import React, {Component, useEffect, useState} from 'react';
import {navigate} from "@reach/router";

import {graphqlOperation} from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import {API} from "aws-amplify";
import ReviewLoopList from "./ReviewLoopList";
import ReviewLoopListItemAction from "./ReviewLoopListItemAction";
import ReviewLoopListItem from "./ReviewLoopListItem";
import { toast } from 'react-semantic-toasts';
import _ from "lodash";
import moment from "moment";
const SORT_FIELDS = [
    {
        key: 'createdat',
        text: 'Date',
        value: 'createdAt'
    },
    {
        key: 'firstname',
        text: 'First Name',
        value: 'firstName'
    },
    {
        key: 'lastname',
        text: 'Last Name',
        value: 'lastName'
    },
];

function GroupContactsList(props) {
    const [items, setItems] = useState(props.items);

    useEffect(() => {
        if (!_.isEqual(props.items, items)) {
            setItems(props.items);
        }
    }, [props]);


    function sortItems (field, direction = 'DESC') {
        let sorted = _.sortBy(items, [field]);
        if(direction === 'DESC') {
            sorted = _.reverse(sorted);
        }
        setItems(sorted);
    }

    async function deleteItem (contactGroup, update = true) {
        const input = {
            id: contactGroup.id,
            deleted: true
        };
        try {
            //optimistic update
            items.splice(items.findIndex(item => item.id === contactGroup.id), 1);
            setItems(items);

            await API.graphql(graphqlOperation(mutations.updateContactGroup, { input }));

            if (update) {
                toast({
                    type: 'success',
                    icon: 'envelope',
                    title: 'Success',
                    description: `Deleted ${contactGroup.contact.firstName} ${contactGroup.contact.lastName}`,
                    animation: 'bounce',
                    time: 5000,
                    size: 'mini'
                });
                props.refresh();
            }
        } catch (e) {
            toast({
                type: 'error',
                icon: 'envelope',
                title: 'Error',
                description: `Error deleting ${contactGroup.contact.firstName} ${contactGroup.contact.lastName}`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
            setItems([...items, contactGroup])
        }
    }

    async function deleteItems(contactGroups) {
        for (const contactGroup of contactGroups) {
            items.splice(items.findIndex(item => item.id === contactGroup.id), 1);
        }
        setItems(items);

        const notDeleted = [];

        let count = 0;
        for (const contactGroup of contactGroups) {
            try {
                const input = {
                    id: contactGroup.id,
                    deleted: true
                };
                await API.graphql(graphqlOperation(mutations.updateContactGroup, { input }));
                count++;
            } catch (e) {
                toast({
                    type: 'error',
                    icon: 'envelope',
                    title: 'Error',
                    description: `Error deleting ${contactGroup.contact.firstName} ${contactGroup.contact.lastName}`,
                    animation: 'bounce',
                    time: 5000,
                    size: 'mini'
                });
                notDeleted.push(contactGroup)
            }
        }
        setItems([...items, ...notDeleted]);

        toast({
            type: 'success',
            icon: 'envelope',
            title: 'Success',
            description: `Deleted ${count}/${contactGroups.length} contacts`,
            animation: 'bounce',
            time: 5000,
            size: 'mini'
        });
    }

    return (
        <ReviewLoopList
            {...props}
            reviewLoopListItem={props => (
                <ReviewLoopListItem
                    {...props}
                    primaryContent={({item}) => (<span>{item.contact.firstName} {item.contact.lastName}</span>)}
                    secondaryContent={({item}) => <span>{item.contact.email} {item.contact.phone}</span>}
                    otherContent={({item}) => <span>{moment().diff(moment(item.contact.createdAt), 'hours') <= 9 ? moment(item.contact.createdAt).fromNow() : moment(item.contact.createdAt).isSame(moment(), 'day') ? moment(item.contact.createdAt).format("D MMM H:m A") : moment(item.contact.createdAt).isSame(moment(), 'year') ? moment(item.contact.createdAt).format("D MMM") : moment(item.contact.createdAt).format("D/MM/YYYY")}</span>}
                    actionsContainer={({item}) => (
                        <>
                            {props.itemActions ? <ReviewLoopListItemAction icon='trash alternate outline' tooltip='Delete' color='red' onClick={()=>deleteItem(item)}/>: false}
                        </>
                    )}
                />
            )}
            items={items}
            loading={props.loading}
            loaded={props.loaded}
            errors={[]}
            messages={props.messages || []}
            onRefresh={props.refresh}
            onSort={sortItems}
            sortFields={SORT_FIELDS}
            defaultSort={{field: 'firstName', order: 'ASC'}}
            listActions={
                props.listActions ? [
                    { icon: 'send', tooltip: 'Request Review', onClick: (items, contacts) => navigate("/requests/new", { state: {groupContacts: contacts} })},
                    { icon: 'trash alternate outline', tooltip: 'Delete', onClick:  (items, contacts) => deleteItems(contacts) },
                ] : false
            }
        />
    )
}

export default GroupContactsList
