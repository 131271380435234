import React from "react";
import {Menu, Tab} from "semantic-ui-react";
import AccountForm from "./AccountForm";
import IntegrationsForm from "./IntegrationForm";
import EmailIntegrationForm from "./EmailIntegrationForm";
import useUser from "../../hooks/useUser";

const OwnerTabs = [
    {
        menuItem: 'Account',
        render: () => <Tab.Pane attached={false}><AccountForm/></Tab.Pane>,
    },
    // {
    //     menuItem: 'Theme',
    //     render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
    // },
    {
        menuItem: (
            <Menu.Item key='messages' >
                Integrations
            </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false} ><IntegrationsForm /></Tab.Pane>,
    },
    {
        menuItem: 'Email',
        render: () => <Tab.Pane attached={false}><EmailIntegrationForm /></Tab.Pane>
    },
];

const UserTabs = [
    {
        menuItem: 'Email',
        render: () => <Tab.Pane attached={false}><EmailIntegrationForm /></Tab.Pane>
    }
];

function Settings(props) {

    const [user,,] = useUser();

    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={user && user.isOwner ? OwnerTabs : UserTabs}
        />
    )
}

export default Settings;
