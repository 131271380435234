import {useEffect, useState} from "react";

import {API, graphqlOperation, Auth} from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";


const useUsers = (initialUsers = []) => {

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(initialUsers);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            await getUsers();
            setLoading(false);
        }
        fetch();

    }, []);

    async function getUsers() {
        const { data: { listUsers: { items : users } }} = await API.graphql(graphqlOperation(queries.listUsers));
        setUsers(users);
    }

    async function createUser({email, firstName, lastName, phoneNumber, userAccountId, isOwner= false}) {
        try {

        let apiName = 'AdminQueries';
        let path = '/addUser';
        let myInit = {
            body: {
                email,
                firstName,
                lastName,
                phoneNumber
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };
        let {data} = await API.post(apiName, path, myInit);

        console.log(data);

        const { data: { createUser: user }} = await API.graphql(
            graphqlOperation(mutations.createUser, {
                input: {
                    id: data.User.Username,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber,
                    userAccountId: userAccountId,
                    isOwner: isOwner
                }
            })
        );
        setUsers([...users, user]);
        } catch (e) {
            throw e;
        }
    }

    async function deleteUser({id}){
        try {

            let apiName = 'AdminQueries';
            let path = '/deleteUser';
            let myInit = {
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            };
            await API.post(apiName, path, myInit);

            await API.graphql(
                graphqlOperation(mutations.deleteUser, {
                    input: {
                        id,
                    }
                })
            );

            await getUsers();
        } catch (e) {
            throw e;
        }
    }

    return [users, createUser, deleteUser, loading];
};

export default useUsers;
