import * as React from 'react';
import {Button, Dropdown, Icon, Menu, Popup} from 'semantic-ui-react'
import {API, Auth, graphqlOperation, Storage} from 'aws-amplify';

import Logo from '../Logo';
import {Link} from "@reach/router";
import * as queries from "../../graphql/queries";

const styleMenu = el => {
    if (el) {
        el.style.setProperty('margin-top', '0rem', 'important');
        el.style.setProperty('margin-bottom', '0rem', 'important');
    }
};

export default class TopMenu extends React.Component {

    state = {
        loading: true,
        logo: '',
        isAdmin: false
    };

    componentDidMount(): void {
        this.getAccount();
        Auth.currentSession().then(session =>  {
            let groups = session.accessToken.payload['cognito:groups'];
            this.setState({ isAdmin: groups ? groups.indexOf('Admin') !== -1 : false })
        }).catch(err => console.error(err));
    }

    signOut = async () => {
        try {
            let data = await Auth.signOut({ global: true });
            this.props.onStateChange('signOut', data)
        } catch(e) {
            console.log('Could not sign out');
        }
    };

    getAccount = async () => {
        const { data: { getUser : user }} = await API.graphql(graphqlOperation(queries.getUser,  {id: (await Auth.currentAuthenticatedUser()).username }));
        user.account.logo && await this.fetchImage(user.account.logo.key);
        this.setState({loading: false})
    };

    fetchImage = async (key) => {
        try {
            const imageData = await Storage.get(key);
            this.setState({logo: imageData})
        } catch(err) {
            console.log('error: ', err)
        }
    };

    render() {
        const { loading, logo, isAdmin } = this.state;
        return (
            <div ref={styleMenu}>
                <Menu borderless secondary>
                    <Menu.Item
                        as={Link}
                        to="/"
                        name='home'
                        fitted
                    >
                      <Logo src={logo} loading={loading} />
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content="Add" trigger={
                            <Dropdown icon={{name: 'add', size: 'large', color: 'purple'}} className='icon link item'>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="requests" icon='star' text='Request Review'/>
                                    <Dropdown.Item as={Link} to="contacts/import" icon='add user' text='Add Contacts'/>
                                    <Dropdown.Item as={Link} to="contacts/groups/add" icon='group' text='Add Group' />
                                    {/*<Dropdown.Item as={Link} to="contacts/imports/new" icon='file alternate' text='Import Contacts'/>*/}
                                </Dropdown.Menu>
                            </Dropdown>
                        } />
                        <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content="Settings" trigger={
                            <Dropdown icon={{name: 'user circle', size: 'big'}} className='link item icon'>
                                <Dropdown.Menu>
                                    {/*<Dropdown.Item><Icon name="user outline"/>Profile</Dropdown.Item>*/}
                                    <Dropdown.Item as={Link} to="/settings"><Icon name="setting"/>Settings</Dropdown.Item>
                                    {
                                       isAdmin && <Dropdown.Item as={Link} to="/admin"><Icon name="puzzle"/>Admin</Dropdown.Item>
                                    }
                                    <Dropdown.Divider/>
                                    <Dropdown.Item>
                                        <Button onClick={this.signOut} color='purple'>Sign Out</Button>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        } />
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}
