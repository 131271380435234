import React, {Component} from 'react';
import {Router} from "@reach/router";
import ReviewForm from "./ReviewForm";

class ContactsAllTab extends Component {

    render() {
        return (
            <Router>
                {/*<ReviewListWithData path="/*" />*/}
                <ReviewForm path="/*" />
                <ReviewForm path="new" />
            </Router>
        )
    }
}

export default ContactsAllTab;
