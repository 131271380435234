import React, {Component} from "react";
import {Icon, Popup} from "semantic-ui-react";


class ResponseStatusButton extends Component {

    handleClick = async (e) => {
        const { responseId, status, onUpdate } = this.props;
        try {
            await onUpdate(responseId, status)
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { status, currentStatus } = this.props;
        const activeProps = status !== currentStatus ? {color: status === 'published' ? 'blue' : 'red', link: true, disabled:false} : {link: false, disabled: true};
        const disabled = status === currentStatus;
        return (
            <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' disabled={disabled} content={ status === 'published' ? 'publish' : 'archive' } trigger={
                <Icon name={status === 'published' ? 'world' : 'archive'} {...activeProps} onClick={this.handleClick}/>
            } />
        )
    }
}

export default ResponseStatusButton;
