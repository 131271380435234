import React from "react";
import PropTypes from 'prop-types';
import {Icon, Popup} from "semantic-ui-react";


function ReviewLoopListItemAction(props) {
    return (
        <Popup
            basic
            style={{opacity:0.9}}
            inverted
            position='bottom center'
            size='mini'
            content={props.tooltip}
            trigger={
                <Icon {...props} link name={props.icon} onClick={props.onClick} />
            }
        />
    )
}

ReviewLoopListItemAction.propTypes = {
    icon: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    color: PropTypes.string,
};

ReviewLoopListItemAction.defaultProps = {
};


export default ReviewLoopListItemAction;
