// @flow
// this is an auto generated file. This will be overwritten

export const scanContacts = /* GraphQL */ `
  query ScanContacts {
    scanContacts {
      items {
        id
        firstName
        lastName
        email
        phone
        import {
          id
          name
          file {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        deleted
        createdAt
        updatedAt
        owner
        group {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const scanResponsesByAccountAndStatus = /* GraphQL */ `
  query ScanResponsesByAccountAndStatus(
    $accountID: ID!
    $status: ResponseStatus
  ) {
    scanResponsesByAccountAndStatus(accountID: $accountID, status: $status) {
      items {
        id
        rating
        message
        status
        accountID
        userID
        createdAt
        updatedAt
        owner
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const scanResponsesByStatus = /* GraphQL */ `
  query ScanResponsesByStatus($status: ResponseStatus) {
    scanResponsesByStatus(status: $status) {
      items {
        id
        rating
        message
        status
        accountID
        userID
        createdAt
        updatedAt
        owner
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const getImport = /* GraphQL */ `
  query GetImport($id: ID!) {
    getImport(id: $id) {
      id
      name
      file {
        bucket
        key
        region
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      contacts {
        items {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        nextToken
      }
      owner
      user {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listImports = /* GraphQL */ `
  query ListImports(
    $filter: ModelImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        file {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        owner
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getSes = /* GraphQL */ `
  query GetSes($id: ID!) {
    getSES(id: $id) {
      id
      email
      status
      userID
      owner
      user {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listSeSs = /* GraphQL */ `
  query ListSeSs(
    $filter: ModelSESFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSESs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        status
        userID
        owner
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        googleReviewUrl
        createdAt
        updatedAt
        theme {
          id
          default
          primary
          secondary
          accent
          add
          profile
          reviewsPageIcon
          reviewsPageColor
          contactsPageIcon
          contactsPageColor
          requestsPageIcon
          requestsPageColor
          reviewsTabNewIcon
          reviewsTabNewColor
          reviewsTabArchivedIcon
          reviewsTabArchivedColor
          reviewsTabPublishedIcon
          reviewsTabPublishedColor
          reviewsTabAllIcon
          reviewsTabAllColor
          contactTabContactsIcon
          contactTabContactsColor
          contactTabGroupsIcon
          contactTabGroupsColor
          contactTabImportIcon
          contactTabImportColor
          requestsTabRequestsIcon
          requestsTabRequestsColor
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
        }
        users {
          items {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      logo {
        bucket
        key
        region
        createdAt
        updatedAt
      }
      googleReviewUrl
      createdAt
      updatedAt
      theme {
        id
        default
        primary
        secondary
        accent
        add
        profile
        reviewsPageIcon
        reviewsPageColor
        contactsPageIcon
        contactsPageColor
        requestsPageIcon
        requestsPageColor
        reviewsTabNewIcon
        reviewsTabNewColor
        reviewsTabArchivedIcon
        reviewsTabArchivedColor
        reviewsTabPublishedIcon
        reviewsTabPublishedColor
        reviewsTabAllIcon
        reviewsTabAllColor
        contactTabContactsIcon
        contactTabContactsColor
        contactTabGroupsIcon
        contactTabGroupsColor
        contactTabImportIcon
        contactTabImportColor
        requestsTabRequestsIcon
        requestsTabRequestsColor
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      users {
        items {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      firstName
      lastName
      email
      phone
      import {
        id
        name
        file {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        owner
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      deleted
      createdAt
      updatedAt
      owner
      group {
        items {
          id
          deleted
          createdAt
          updatedAt
          contact {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          owner
          group {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      responses {
        items {
          id
          rating
          message
          status
          accountID
          userID
          createdAt
          updatedAt
          owner
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contact {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          review {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          review {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          owner
        }
        nextToken
      }
      user {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        import {
          id
          name
          file {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        deleted
        createdAt
        updatedAt
        owner
        group {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getContactGroup = /* GraphQL */ `
  query GetContactGroup($id: ID!) {
    getContactGroup(id: $id) {
      id
      deleted
      createdAt
      updatedAt
      contact {
        id
        firstName
        lastName
        email
        phone
        import {
          id
          name
          file {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        deleted
        createdAt
        updatedAt
        owner
        group {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      owner
      group {
        id
        name
        createdAt
        updatedAt
        contacts {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        owner
        reviews {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const listContactGroups = /* GraphQL */ `
  query ListContactGroups(
    $filter: ModelContactGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        createdAt
        updatedAt
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        owner
        group {
          id
          name
          createdAt
          updatedAt
          contacts {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      createdAt
      updatedAt
      contacts {
        items {
          id
          deleted
          createdAt
          updatedAt
          contact {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          owner
          group {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      owner
      reviews {
        items {
          id
          createdAt
          updatedAt
          group {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          review {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          owner
        }
        nextToken
      }
      user {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        contacts {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        owner
        reviews {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      rating
      message
      status
      accountID
      userID
      createdAt
      updatedAt
      owner
      account {
        id
        name
        logo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        googleReviewUrl
        createdAt
        updatedAt
        theme {
          id
          default
          primary
          secondary
          accent
          add
          profile
          reviewsPageIcon
          reviewsPageColor
          contactsPageIcon
          contactsPageColor
          requestsPageIcon
          requestsPageColor
          reviewsTabNewIcon
          reviewsTabNewColor
          reviewsTabArchivedIcon
          reviewsTabArchivedColor
          reviewsTabPublishedIcon
          reviewsTabPublishedColor
          reviewsTabAllIcon
          reviewsTabAllColor
          contactTabContactsIcon
          contactTabContactsColor
          contactTabGroupsIcon
          contactTabGroupsColor
          contactTabImportIcon
          contactTabImportColor
          requestsTabRequestsIcon
          requestsTabRequestsColor
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
        }
        users {
          items {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
          nextToken
        }
      }
      contact {
        id
        firstName
        lastName
        email
        phone
        import {
          id
          name
          file {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        deleted
        createdAt
        updatedAt
        owner
        group {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      review {
        id
        title
        message
        status
        createdAt
        updatedAt
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        owner
        contacts {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        groups {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      user {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        message
        status
        accountID
        userID
        createdAt
        updatedAt
        owner
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const responsesByUser = /* GraphQL */ `
  query ResponsesByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        message
        status
        accountID
        userID
        createdAt
        updatedAt
        owner
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const responsesByAccount = /* GraphQL */ `
  query ResponsesByAccount(
    $accountID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByAccount(
      accountID: $accountID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        message
        status
        accountID
        userID
        createdAt
        updatedAt
        owner
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      title
      message
      status
      createdAt
      updatedAt
      responses {
        items {
          id
          rating
          message
          status
          accountID
          userID
          createdAt
          updatedAt
          owner
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contact {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          review {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        nextToken
      }
      owner
      contacts {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          review {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          owner
        }
        nextToken
      }
      groups {
        items {
          id
          createdAt
          updatedAt
          group {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          review {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          owner
        }
        nextToken
      }
      user {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        status
        createdAt
        updatedAt
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        owner
        contacts {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        groups {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getReviewContact = /* GraphQL */ `
  query GetReviewContact($id: ID!) {
    getReviewContact(id: $id) {
      id
      createdAt
      updatedAt
      contact {
        id
        firstName
        lastName
        email
        phone
        import {
          id
          name
          file {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        deleted
        createdAt
        updatedAt
        owner
        group {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      review {
        id
        title
        message
        status
        createdAt
        updatedAt
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        owner
        contacts {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        groups {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      owner
    }
  }
`;
export const listReviewContacts = /* GraphQL */ `
  query ListReviewContacts(
    $filter: ModelReviewContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        contact {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getReviewGroup = /* GraphQL */ `
  query GetReviewGroup($id: ID!) {
    getReviewGroup(id: $id) {
      id
      createdAt
      updatedAt
      group {
        id
        name
        createdAt
        updatedAt
        contacts {
          items {
            id
            deleted
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            owner
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        owner
        reviews {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      review {
        id
        title
        message
        status
        createdAt
        updatedAt
        responses {
          items {
            id
            rating
            message
            status
            accountID
            userID
            createdAt
            updatedAt
            owner
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        owner
        contacts {
          items {
            id
            createdAt
            updatedAt
            contact {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        groups {
          items {
            id
            createdAt
            updatedAt
            group {
              id
              name
              createdAt
              updatedAt
              owner
            }
            review {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
        user {
          id
          isOwner
          firstName
          lastName
          email
          phone
          photo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
          contacts {
            items {
              id
              firstName
              lastName
              email
              phone
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              title
              message
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      owner
    }
  }
`;
export const listReviewGroups = /* GraphQL */ `
  query ListReviewGroups(
    $filter: ModelReviewGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        group {
          id
          name
          createdAt
          updatedAt
          contacts {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        review {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const listThemes = /* GraphQL */ `
  query ListThemes(
    $filter: ModelThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        default
        primary
        secondary
        accent
        add
        profile
        reviewsPageIcon
        reviewsPageColor
        contactsPageIcon
        contactsPageColor
        requestsPageIcon
        requestsPageColor
        reviewsTabNewIcon
        reviewsTabNewColor
        reviewsTabArchivedIcon
        reviewsTabArchivedColor
        reviewsTabPublishedIcon
        reviewsTabPublishedColor
        reviewsTabAllIcon
        reviewsTabAllColor
        contactTabContactsIcon
        contactTabContactsColor
        contactTabGroupsIcon
        contactTabGroupsColor
        contactTabImportIcon
        contactTabImportColor
        requestsTabRequestsIcon
        requestsTabRequestsColor
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTheme = /* GraphQL */ `
  query GetTheme($id: ID!) {
    getTheme(id: $id) {
      id
      default
      primary
      secondary
      accent
      add
      profile
      reviewsPageIcon
      reviewsPageColor
      contactsPageIcon
      contactsPageColor
      requestsPageIcon
      requestsPageColor
      reviewsTabNewIcon
      reviewsTabNewColor
      reviewsTabArchivedIcon
      reviewsTabArchivedColor
      reviewsTabPublishedIcon
      reviewsTabPublishedColor
      reviewsTabAllIcon
      reviewsTabAllColor
      contactTabContactsIcon
      contactTabContactsColor
      contactTabGroupsIcon
      contactTabGroupsColor
      contactTabImportIcon
      contactTabImportColor
      requestsTabRequestsIcon
      requestsTabRequestsColor
      createdAt
      updatedAt
      account {
        id
        name
        logo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        googleReviewUrl
        createdAt
        updatedAt
        theme {
          id
          default
          primary
          secondary
          accent
          add
          profile
          reviewsPageIcon
          reviewsPageColor
          contactsPageIcon
          contactsPageColor
          requestsPageIcon
          requestsPageColor
          reviewsTabNewIcon
          reviewsTabNewColor
          reviewsTabArchivedIcon
          reviewsTabArchivedColor
          reviewsTabPublishedIcon
          reviewsTabPublishedColor
          reviewsTabAllIcon
          reviewsTabAllColor
          contactTabContactsIcon
          contactTabContactsColor
          contactTabGroupsIcon
          contactTabGroupsColor
          contactTabImportIcon
          contactTabImportColor
          requestsTabRequestsIcon
          requestsTabRequestsColor
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
        }
        users {
          items {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isOwner
        firstName
        lastName
        email
        phone
        photo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        account {
          id
          name
          logo {
            bucket
            key
            region
            createdAt
            updatedAt
          }
          googleReviewUrl
          createdAt
          updatedAt
          theme {
            id
            default
            primary
            secondary
            accent
            add
            profile
            reviewsPageIcon
            reviewsPageColor
            contactsPageIcon
            contactsPageColor
            requestsPageIcon
            requestsPageColor
            reviewsTabNewIcon
            reviewsTabNewColor
            reviewsTabArchivedIcon
            reviewsTabArchivedColor
            reviewsTabPublishedIcon
            reviewsTabPublishedColor
            reviewsTabAllIcon
            reviewsTabAllColor
            contactTabContactsIcon
            contactTabContactsColor
            contactTabGroupsIcon
            contactTabGroupsColor
            contactTabImportIcon
            contactTabImportColor
            requestsTabRequestsIcon
            requestsTabRequestsColor
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
          }
          users {
            items {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contacts {
          items {
            id
            firstName
            lastName
            email
            phone
            import {
              id
              name
              createdAt
              updatedAt
              owner
            }
            deleted
            createdAt
            updatedAt
            owner
            group {
              nextToken
            }
            responses {
              nextToken
            }
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            reviews {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            title
            message
            status
            createdAt
            updatedAt
            responses {
              nextToken
            }
            owner
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isOwner
      firstName
      lastName
      email
      phone
      photo {
        bucket
        key
        region
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      account {
        id
        name
        logo {
          bucket
          key
          region
          createdAt
          updatedAt
        }
        googleReviewUrl
        createdAt
        updatedAt
        theme {
          id
          default
          primary
          secondary
          accent
          add
          profile
          reviewsPageIcon
          reviewsPageColor
          contactsPageIcon
          contactsPageColor
          requestsPageIcon
          requestsPageColor
          reviewsTabNewIcon
          reviewsTabNewColor
          reviewsTabArchivedIcon
          reviewsTabArchivedColor
          reviewsTabPublishedIcon
          reviewsTabPublishedColor
          reviewsTabAllIcon
          reviewsTabAllColor
          contactTabContactsIcon
          contactTabContactsColor
          contactTabGroupsIcon
          contactTabGroupsColor
          contactTabImportIcon
          contactTabImportColor
          requestsTabRequestsIcon
          requestsTabRequestsColor
          createdAt
          updatedAt
          account {
            id
            name
            logo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            googleReviewUrl
            createdAt
            updatedAt
            theme {
              id
              default
              primary
              secondary
              accent
              add
              profile
              reviewsPageIcon
              reviewsPageColor
              contactsPageIcon
              contactsPageColor
              requestsPageIcon
              requestsPageColor
              reviewsTabNewIcon
              reviewsTabNewColor
              reviewsTabArchivedIcon
              reviewsTabArchivedColor
              reviewsTabPublishedIcon
              reviewsTabPublishedColor
              reviewsTabAllIcon
              reviewsTabAllColor
              contactTabContactsIcon
              contactTabContactsColor
              contactTabGroupsIcon
              contactTabGroupsColor
              contactTabImportIcon
              contactTabImportColor
              requestsTabRequestsIcon
              requestsTabRequestsColor
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
          }
        }
        users {
          items {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
          nextToken
        }
      }
      contacts {
        items {
          id
          firstName
          lastName
          email
          phone
          import {
            id
            name
            file {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            contacts {
              nextToken
            }
            owner
            user {
              id
              isOwner
              firstName
              lastName
              email
              phone
              createdAt
              updatedAt
            }
          }
          deleted
          createdAt
          updatedAt
          owner
          group {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        nextToken
      }
      groups {
        items {
          id
          name
          createdAt
          updatedAt
          contacts {
            items {
              id
              deleted
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          reviews {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          title
          message
          status
          createdAt
          updatedAt
          responses {
            items {
              id
              rating
              message
              status
              accountID
              userID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          owner
          contacts {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          groups {
            items {
              id
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            isOwner
            firstName
            lastName
            email
            phone
            photo {
              bucket
              key
              region
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            account {
              id
              name
              googleReviewUrl
              createdAt
              updatedAt
            }
            contacts {
              nextToken
            }
            groups {
              nextToken
            }
            reviews {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
