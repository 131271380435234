import React, {useEffect, useState} from "react";
import {Button, Form, Icon, Input, Segment} from "semantic-ui-react";
import useSESSettings from "../../hooks/useSESSettings";
import API from "@aws-amplify/api";
import {toast} from "react-semantic-toasts";
import ReviewLoopActionButton from "../ReviewLoopActionButton";

function EmailIntegrationForm(props) {
    // form email field
    const [ email, setEmail ] = useState('');
    const [status, setStatus] = useState('Not Started');
    // aws ses
    const [sesSettings, createSesSettings, updateSesSettings, refresh ] = useSESSettings();

    function setFields() {
        setEmail(sesSettings.email);
        setStatus(sesSettings.status);
    }

    useEffect(() => {
        if (sesSettings && sesSettings.email) {
            setFields();
        }
    }, [sesSettings]);

    const handleSubmit = async () => {
        await verifyEmail();
        if (sesSettings.id) await updateSesSettings(email);
        else await createSesSettings(email);
    };

    const verifyEmail = async () => {
        try {
            let myInit = {
                body: {email}
            };
            await API.post('email', '/admin/verify', myInit);
            toast({
                type: 'info',
                icon: 'message',
                title: 'Verification Email Sent',
                description: `Please check your email inbox for a verification email.`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        } catch (e) {
            console.log(e);
            toast({
                type: 'error',
                icon: 'message',
                title: 'Verification Email Send Failure',
                description: `We were unable to send a verification email at this time. Please try again later.`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        }
    };

    async function handleRefresh() {
        await refresh();
        if (sesSettings.status === 'Success') {
            toast({
                type: 'success',
                icon: 'certificate',
                title: 'Email Verified',
                description: `We have successfully verified ${sesSettings.email} for sending emails.`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        } else {
            toast({
                type: 'error',
                icon: 'certificate',
                title: 'Email Could Not Be Verified',
                description: `We could not verify ${sesSettings.email} at this time. Please click the link sent in the verification email and try again.`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        }

    }

    let action = <></>;
    switch (status) {
        case 'Success': action = <><Icon.Group><Icon size='big' color='green' name='certificate' /><Icon name='check' color='blue' /></Icon.Group><Form.Field control={Button} color='purple' onClick={() => setStatus('Change')}>Change</Form.Field></>; break;
        case 'Failed':
        case 'Change':
        case 'Not Started': action = <><Form.Field control={Button} disabled={sesSettings.email === email} color='purple' onClick={handleSubmit}>Verify</Form.Field>{ status === 'Change' && <Form.Field control={Button} color='purple' onClick={setFields}>Cancel</Form.Field>}</>; break;
        default: action = <><Form.Field control={Button} color='purple' onClick={verifyEmail}>Send Again</Form.Field><Form.Field control={Button} color='purple' onClick={() => setStatus('Change')}>Change</Form.Field></>; break;
    }

    return (
        <Segment basic clearing>
            <Form>
                <Form.Group inline>
                    <Form.Field disabled={status === 'Success' || status === 'Pending'} control={Input} width={6} inline label='Email' name='email' onChange={(e, {value}) => setEmail(value)} value={email}  />
                    {  status === 'Pending' && <ReviewLoopActionButton disabled={sesSettings.email === email && status !== 'Pending'} icon='refresh' onClick={handleRefresh} /> }
                    {action}
                </Form.Group>
            </Form>
        </Segment>
    )
}

export default EmailIntegrationForm;
