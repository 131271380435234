import React, {Component} from 'react';
import { Icon, Rating} from 'semantic-ui-react'
import moment from "moment";
import ReviewLoopListItem from "./ReviewLoopListItem";
import {navigate} from "@reach/router";

const Details = (props) => {
    const {status, createdAt} = props;
    let label = {};
    switch (status) {
        case 'new': label = {display: true, color: 'purple', icon: 'inbox'}; break;
        case 'archived': label = {display: true, color: 'red', icon: 'archive'}; break;
        case 'published': label = {display: true, color: 'blue', icon: 'world'}; break;
        default:
            label = {hide: false, color: 'orange', icon: 'inbox'}; break;
    }

    let date = moment(createdAt);
    if (moment().diff(date, 'hours') <= 9) {
        date = date.fromNow()
    } else if (date.isSame(moment(), 'day')) {
        date = date.format("D MMM H:m A")
    } else if (date.isSame(moment(), 'year')) {
        date = date.format("D MMM")
    } else {
        date = date.format("D/MM/YYYY")
    }

    return (
        <>
            {
                label.display && <Icon style={{marginRight:5}} color={label.color} name={label.icon} />
            }
            <span>{date}</span>
        </>
    )
};



export default class ResponseItem extends Component {

    state = {
        hover: false
    };

    onClick = (e, {data}) => {
        navigate(`/reviews/view/${data.id}`, { state: data});
    };

    render() {
        const { itemActions: Action, isSelected } = this.props;


        return (
                <ReviewLoopListItem
                    {...this.props}
                    isSelected={isSelected}
                    primaryContent={({item}) => (
                        <>
                            <Rating icon={"star"} rating={item.rating} maxRating={5} size={"huge"} disabled/>
                            <span style={{marginLeft: 20}}>{item.contact.firstName} {item.contact.lastName}</span>
                        </>
                    )}
                    secondaryContent={({item}) => (<span ><i>{item.message}</i></span>)}
                    otherContent={({item}) => (<Details status={item.status} createdAt={item.createdAt}/>)}
                    actionsContainer={(props) => (<Action {...props}/>)}
                    onClick={this.onClick}
                />
        )
    }

}

