// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {Button, Container, Dimmer, Divider, Dropdown, Form, Header, Input, Progress, Sticky} from "semantic-ui-react";

import {API, graphqlOperation} from "aws-amplify";

import FileDragAndDrop from "./FileDragAndDrop";
import ReviewLoopActionButton from "./ReviewLoopActionButton";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import * as queries from "../graphql/queries";
import * as mutations from '../graphql/mutations';
import {toast} from "react-semantic-toasts";


class ContactsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts: [
                {firstName: '', lastName: '', email: '' , phone: ''},
            ],
            loading: false,
            progress: 0,
            total: 0,
            name: '',
            file: '',
            groups: [],
        };
    }

    componentDidMount = async () => {
        this.#fetchGroups()
    };

    #fetchGroups = async () => {
        try {
            const { data : { listGroups } } = await API.graphql(graphqlOperation(queries.listGroups, {limit: 1000}));
            const items = listGroups ? listGroups.items.map(item => ({key: item.id, value: item, text: item.name})) : [];
            this.setState({ options: items, groups: listGroups.items });
        } catch (e) {
            this.setState({ errors: [e] });
        }
    };

    #createGroup =  async (name) => {
        const { data: {createGroup}} = await API.graphql(graphqlOperation(mutations.createGroup, {input: {name}}));
        return createGroup;
    };

    handleChange = (e, { name, value, index}) => {
        const { contacts } = this.state;
        contacts[index] = {...contacts[index], [name] : value};
        this.setState({ contacts });
    };

    handleGroupChange = (e, {value}) => this.setState({group: value });

    handleAddition = async (e, { value }) => {
        console.log(value)
        const group = await this.#createGroup(value);
        this.setState((prev) => ({...prev, groups: [...prev.groups, {key: group.id, value: group, text: group.name}] }))
    };

    handleFileParse = (file, contacts) => {
        this.setState({file, contacts});
    };

    handleAdd = () => {
        const {contacts} = this.state;
        contacts.push({firstName: '', lastName: '', email: '' , phone: ''});
        this.setState({ contacts })
    };

    handleRemove = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        const {contacts} = this.state;
        contacts.splice(index,1);
        this.setState({ contacts })
    };

    createContact = (contact) => {
        return new Promise(async (resolve, reject) => {
            try {
                const {data: createContact} = await API.graphql(graphqlOperation(mutations.createContact,
                    {
                        input: {...contact}
                    }));
                resolve(createContact)
            } catch (e) {
                reject(e)
            }

        });
    };

    handleSubmit = async (send = false) => {
        const { navigate } = this.props;
        const { contacts: contactInputs, group } = this.state;

        try {

            const contacts = contactInputs.filter(c => c.firstName && c.lastName && c.email);
            this.setState({loading: true, total: contacts.length});

            const promises = [];
            let newContacts = [];
            for (let i = 0; i < contacts.length; i++) {
                const contact = contacts[i];
                contact.phone = contact.phone || undefined;
                promises.push(this.createContact(contact));

                const isLast = i + 1 >= contacts.length;
                console.log( contacts.length, isLast);
                if(i % 25 === 0 || isLast) {
                    console.log('made it');
                    const done = await Promise.allSettled(promises);
                    console.log(done);
                    newContacts = [...newContacts, ...done.map(item => item.value.createContact)];
                    this.setState((state) => {
                        return {progress: state.progress + done.length};
                    });
                    promises.length = 0
                }
            }

            if(group) await this.groupContacts(newContacts, group);

            this.setState({
                contacts: [{firstName: '', lastName: '', email: '' , phone: ''}],
                loading: false,
                progress: 0,
                total:0
            });

            if (send) navigate('/requests', {state: {contacts : newContacts}});
            else navigate('/contacts');
        } catch (e) {
            console.log(e);
        }
    };

    groupContacts = async (contacts, group) => {

        this.setState({loading: true});
        try {

            let groupDB;
            if(!group.id) {
                ({ data: {createGroup: groupDB}} = await API.graphql(graphqlOperation(mutations.createGroup, {input: {name: group.name}})));
                groupDB.contacts = { items : [] }
            } else {
                ({data: {getGroup: groupDB}} = await API.graphql(graphqlOperation(queries.getGroup, {id: group.id})));
            }


            const newGroupContacts = [];
            for (const contact of contacts) {
                const index = groupDB.contacts.items.findIndex(item => item.id === contact.id);
                if (index >= 0) {
                    continue;
                }
                newGroupContacts.push(contact);
            }

            for (const contact of newGroupContacts) {
                await API.graphql(graphqlOperation(mutations.createContactGroup, {
                    input: {
                        contactGroupContactId: contact.id,
                        contactGroupGroupId: groupDB.id
                    }
                }));
            }
            toast({
                type: 'success',
                icon: 'envelope',
                title: 'Success',
                description: `Contacts have been added to group: ${groupDB.name}`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
            this.setState({loading: false});
            this.props.navigate(`./groups/${groupDB.id}`)

        } catch (e) {
            console.error(e)
            toast({
                type: 'error',
                icon: 'envelope',
                title: 'Error',
                description: `Error grouping contacts`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
            this.setState({loading: false});
        }
    }

    render() {

        const { contacts, loading, progress, total, options, group } = this.state;


        return (
            <Container>
                <Dimmer active={loading} inverted  page>
                    <Container  textAlign='center'>
                        <Header as='h1'>Importing Contacts</Header>
                        <Progress color='purple' value={progress} total={total} progress='ratio' />
                    </Container>
                </Dimmer>


                <br/>

                <Dropdown
                    style={{width: 200, overflow: 'none'}}
                    button
                    basic
                    labeled
                    icon='group'
                    className='icon'
                    placeholder='Select group'
                    options={options}
                    search
                    value={group}
                    allowAdditions
                    onAddItem={this.handleAddition}
                    onChange={this.handleGroupChange}
                />

                <FileDragAndDrop onFileProcessed={this.handleFileParse} />

                <Divider horizontal>Or</Divider>

                <Form onSubmit={() => this.handleSubmit()}>
                    {
                        contacts.map((contact, index) => {
                            const isLast = index === contacts.length - 1;
                            return (
                                <Form.Group key={index} widths='equal'>
                                    <Form.Input
                                        placeholder='First Name'
                                        name={`firstName`}
                                        value={contact.firstName}
                                        index={index}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Input
                                        placeholder='Last Name'
                                        name='lastName'
                                        value={contact.lastName}
                                        index={index}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Input
                                        placeholder='Email'
                                        name='email'
                                        value={contact.email}
                                        index={index}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Input
                                        placeholder='Phone'
                                        name='phone'
                                        value={contact.phone}
                                        index={index}
                                        onChange={this.handleChange}
                                    />
                                    {
                                        !isLast && <ReviewLoopActionButton circular icon='minus circle' onClick={(e)=>this.handleRemove(e, index)} />
                                    }
                                    {
                                        isLast && <ReviewLoopActionButton circular icon='plus circle' onClick={this.handleAdd} />
                                    }
                                </Form.Group>
                            )
                        })
                    }
                    <Sticky bottomOffset={0}>
                    <Button.Group floated='right' color='purple'>
                        <Button animated>
                            <Button.Content visible>Import</Button.Content>
                            <Button.Content hidden>
                                <Icon name='upload' />
                            </Button.Content>
                        </Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={[
                                { key: 'send', icon: 'send', text: 'Import and Send', value: 'send', onClick: () => this.handleSubmit(true) },
                            ]}
                            trigger={<React.Fragment />}
                        />
                    </Button.Group>
                    </Sticky>

                </Form>
            </Container>
        );

    }
}

export default ContactsForm;
