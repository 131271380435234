import React, {Component} from 'react';
import {navigate} from "@reach/router";

import {graphqlOperation} from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import {API} from "aws-amplify";
import ReviewLoopList from "./ReviewLoopList";
import ReviewLoopListItemAction from "./ReviewLoopListItemAction";
import ReviewLoopListItem from "./ReviewLoopListItem";
import { toast } from 'react-semantic-toasts';
import GroupListSegment from "./GroupListSegment";
import _ from "lodash";
import moment from "moment";
const SORT_FIELDS = [
    {
        key: 'createdat',
        text: 'Date',
        value: 'createdAt'
    },
    {
        key: 'firstname',
        text: 'First Name',
        value: 'firstName'
    },
    {
        key: 'lastname',
        text: 'Last Name',
        value: 'lastName'
    },
];
class ContactsList extends Component {

    state = {
        items: [],
        errors: [],
        loaded: false,
        loading: false,
        messages: {}
    };

    componentDidMount = async () => {
        this.fetchItems();
    };

    fetchItems = async () => {
        try {
            const { data : { scanContacts } } = await API.graphql(graphqlOperation(
                `query ScanContacts {
                            scanContacts {
                                items {
                                    id
                                    firstName
                                    lastName
                                    email
                                    phone
                                    createdAt
                                }
                            }
                        }
                        `
                , {}));
            const items = scanContacts ? scanContacts.items : [];

            this.setState({ items, loaded: true }, () => this.sortItems('firstName', 'ASC'));
        } catch (e) {
            this.setState({ errors: [e], loaded: true });
        }
    };

    refresh = async () => {
        try {
            await this.fetchItems();
        } catch (e) {
            this.setState({errors: [e]})
        }
    };

    deleteItem = async (contact, update = true) => {
        //optimistic update
        const {items} = this.state;
        items.splice(items.findIndex(item => item.id === contact.id), 1);
        this.setState({items});

        const input = {
            id: contact.id,
            deleted: true
        };
        try {
            await API.graphql(graphqlOperation(mutations.updateContact, { input }));

            if (update) {
                toast({
                    type: 'success',
                    icon: 'envelope',
                    title: 'Success',
                    description: `Deleted ${contact.firstName} ${contact.lastName}`,
                    animation: 'bounce',
                    time: 5000,
                    size: 'mini'
                });
                this.fetchItems();
            }
        } catch (e) {
            toast({
                type: 'error',
                icon: 'envelope',
                title: 'Error',
                description: `Error deleting ${contact.firstName} ${contact.lastName}`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
            this.setState({errors: [e], loading: false })
        }
    };

    deleteItems = async (contacts) => {
        const {items, errors} = this.state;
        for (const contact of contacts) {
            items.splice(items.findIndex(item => item.id === contact.id), 1);
        }
        this.setState({items});

        let count = 0;
        for (const contact of contacts) {
            try {
                const input = {
                    id: contact.id,
                    deleted: true
                };
                API.graphql(graphqlOperation(mutations.updateContact, { input }));
                count++;
            } catch (e) {
                toast({
                    type: 'error',
                    icon: 'envelope',
                    title: 'Error',
                    description: `Error deleting ${contact.firstName} ${contact.lastName}`,
                    animation: 'bounce',
                    time: 5000,
                    size: 'mini'
                });
                this.setState({errors: [...errors, e]})
            }
        }
        toast({
            type: 'success',
            icon: 'envelope',
            title: 'Success',
            description: `Deleted ${count}/${contacts.length} contacts`,
            animation: 'bounce',
            time: 5000,
            size: 'mini'
        });
    };

    groupItems = async (contacts, group) => {

        this.setState({loading: true});
        try {

            let groupDB;
            if(!group.id) {
                ({ data: {createGroup: groupDB}} = await API.graphql(graphqlOperation(mutations.createGroup, {input: {name: group.name}})));
                groupDB.contacts = { items : [] }
            } else {
                ({data: {getGroup: groupDB}} = await API.graphql(graphqlOperation(queries.getGroup, {id: group.id})));
            }


            const newGroupContacts = [];
            for (const contact of contacts) {
                const index = groupDB.contacts.items.findIndex(item => item.id === contact.id);
                if (index >= 0) {
                    continue;
                }
                newGroupContacts.push(contact);
            }

            for (const contact of newGroupContacts) {
                 await API.graphql(graphqlOperation(mutations.createContactGroup, {
                    input: {
                        contactGroupContactId: contact.id,
                        contactGroupGroupId: groupDB.id
                    }
                }));
            }
            toast({
                type: 'success',
                icon: 'envelope',
                title: 'Success',
                description: `Contacts have been added to group: ${groupDB.name}`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
            this.setState({loading: false});
            this.props.navigate(`./groups/${groupDB.id}`)

        } catch (e) {
            console.error(e)
            toast({
                type: 'error',
                icon: 'envelope',
                title: 'Error',
                description: `Error grouping contacts`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
            this.setState({loading: false});
        }
    }

    sortItems = (field, direction = 'DESC') => {
        const { items } = this.state;
        let sorted = _.sortBy(items, [field]);
        if(direction === 'DESC') {
            sorted = _.reverse(sorted);
        }
        this.setState({ items: sorted })
    };

    render() {
        const { items, errors, loading, loaded, messages } = this.state;
        const { listActions, itemActions } = this.props;


        return (
            <ReviewLoopList
                {...this.props}
                reviewLoopListItem={props => (
                    <ReviewLoopListItem
                        {...props}
                        primaryContent={({item}) => (<span>{item.firstName} {item.lastName}</span>)}
                        secondaryContent={({item}) => <span>{item.email} {item.phone}</span>}
                        otherContent={({item}) => <span>{moment().diff(moment(item.createdAt), 'hours') <= 9 ? moment(item.createdAt).fromNow() : moment(item.createdAt).isSame(moment(), 'day') ? moment(item.createdAt).format("D MMM H:m A") : moment(item.createdAt).isSame(moment(), 'year') ? moment(item.createdAt).format("D MMM") : moment(item.createdAt).format("D/MM/YYYY")}</span>}
                        actionsContainer={({item}) => (
                            <>
                                {props.itemActions ? <ReviewLoopListItemAction icon='trash alternate outline' tooltip='Delete' color='red' onClick={()=>this.deleteItem(item)}/> : false}
                            </>
                        )}
                    />
                )}
                items={items}
                loading={loading}
                loaded={loaded}
                errors={errors}
                messages={messages}
                onRefresh={this.refresh}
                onSort={this.sortItems}
                sortFields={SORT_FIELDS}
                defaultSort={{field: 'firstName', order: 'ASC'}}
                listActions={
                    listActions ? [
                        { icon: 'send', tooltip: 'Request Review', onClick: (items, contacts) => navigate("/requests/new", { state: {contacts} })},
                        { icon: 'group', tooltip: 'Group', tooltipRender: GroupListSegment , onClick: (items, contacts, group) => this.groupItems(contacts, group) }, //icon: 'group', tooltip: 'Group', type: 'dropdown', tooltipRender: GroupListSegment, onClick: (items, contacts, group) => this.groupItems(contacts, group) },
                        { icon: 'trash alternate outline', tooltip: 'Delete', onClick:  (items, contacts) => this.deleteItems(contacts) },
                    ] : false
                }
            />
        )
    }
}

export default ContactsList
