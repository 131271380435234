import * as React from 'react';
import {Image, Placeholder, Segment} from 'semantic-ui-react'

import logo from './ReviewsLoopNew.png'
import lpc from './LPC_400x140.png';

export default class Logo extends React.Component {

    render() {
        const { src = logo, alt, size, loading } = this.props;
        const img = alt ? lpc : src || lpc;
        if (loading) {
            if(size) {
                return (
                    <Segment basic textAlign={"center"} style={{paddingTop: 0, paddingBottom: 0}}>
                    <Placeholder as={Image} centred style={{ height: 150, width: 400 }} >
                        <Placeholder.Image  />
                    </Placeholder>
                    </Segment>
                )
            }
            return (
                <Placeholder as={Image} centered style={{ height: 60, width: 150 }}>
                    <Placeholder.Image  />
                </Placeholder>
            )
        }
        if (size) {
            return (<Image src={img} width={400} height={150} centered/>);
        }
        return (<Image src={img} width={150} height={60} centered/>)
    }

}
