import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {Checkbox, List, Transition} from 'semantic-ui-react'


function ReviewLoopListItem(props) {

    const [hover, setHover] = useState(false);

    const {item, primaryContent, secondaryContent, actionsContainer, otherContent, isSelected } = props;


    const handleClick = (e, {checked}) => {
        e.preventDefault();
        e.stopPropagation();
        const { onSelected, item } = props;
        onSelected(item, checked);
    };

    const onMouseEnter = () => {
        setHover(true);
    };

    const onMouseLeave = () => {
        setHover(false);
    };

    const indeterminateProps = props.indeterminate ? { indeterminate: () => props.indeterminate(props) } : {};
    const disabledProps = props.disabled ? { disabled: () => props.disabled(props) } : {};


    return (
        <List.Item onClick={props.onClick} key={item.id} data={item} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <List.Content floated='right'>
                    {
                        actionsContainer || otherContent ?
                            <Transition visible={true} transitionOnMount>
                                {
                                   hover && actionsContainer ? actionsContainer(props) : otherContent ? otherContent(props) : <></>
                                }
                            </Transition>
                            :
                            null
                    }
                </List.Content>

                <List.Content>
                    <List.Header className="left floated">
                        <Checkbox style={{marginLeft: 7, marginRight: 10 }} checked={isSelected} {...disabledProps} {...indeterminateProps} onClick={handleClick}/>
                        {
                            primaryContent(props)
                        }
                    </List.Header>
                </List.Content>
                <List.Content floated='left'>
                    {
                        secondaryContent && secondaryContent(props)
                    }
                </List.Content>
        </List.Item>
    )
}

ReviewLoopListItem.propTypes = {
    item: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    primaryContent: PropTypes.func.isRequired,
    secondaryContent: PropTypes.func,
    actionsContainer: PropTypes.func,
    otherContent: PropTypes.func
};

export default ReviewLoopListItem;

