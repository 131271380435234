import {useEffect, useState} from "react";

import {API, graphqlOperation, Auth} from "aws-amplify";
import * as queries from "../graphql/queries";


const useUser = (userId) => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser()
    }, [userId]);

    async function getUser() {
        const input = {
            id: userId || (await Auth.currentAuthenticatedUser()).username
        };
        const { data: { getUser : user }} = await API.graphql(graphqlOperation(queries.getUser, input));
        console.log(user)
        setUser(user);
    }



    return [user];
};

export default useUser;
