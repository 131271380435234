import React, {Component} from "react";
import {Segment} from "semantic-ui-react";
import htmlToDraft from 'html-to-draftjs';
import {ContentState, EditorState} from 'draft-js';

import {Editor} from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class ReviewPreview extends Component {

    constructor(props) {
        super(props);
        const contentBlock = htmlToDraft(props.body);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
                loading: false
            };
        }
    }



    render() {
        const { recipients, subject } = this.props;
        const { editorState } = this.state;

        return (
            <div>
                <div>
                    {
                        recipients.map((contact, i) => <div key={contact.id}>{ contact.email }</div>)
                    }
                </div>

                <Segment>
                    { subject }
                </Segment>

                <Segment>
                    <Editor
                        editorState={editorState}
                        readOnly
                        toolbarHidden
                    />
                </Segment>


            </div>


        )
    }

}

export default ReviewPreview;
