import React from "react";
import PropTypes from 'prop-types';

import {Icon, Menu, Popup} from "semantic-ui-react";
import {Link, Match} from "@reach/router";


const ReviewLoopMenuButton = (props) => {

    const { tooltip, icon, to, color } = props;

    return (
        <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content={tooltip} trigger={
            <Menu.Item
                as={Link}
                to={to}
                getProps={({isCurrent}) => {
                    return isCurrent ? { color } : null
                }}
                name={icon}
            >
                <Match path={`${to}/*`}>
                    {
                        ({match}) => <Icon name={icon} size='big' color={match ? color : 'grey'} />

                    }
                </Match>
            </Menu.Item>
        } />
    )
};

ReviewLoopMenuButton.propTypes = {
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    color: PropTypes.string,
    activeItem: PropTypes.string,
};

export default ReviewLoopMenuButton;
