import React, {Component, useState} from 'react';
import { toast } from 'react-semantic-toasts';

import * as queries from "../graphql/queries";
import {API, Auth} from "aws-amplify";
import {graphqlOperation} from "@aws-amplify/api";
import ReviewLoopList from "./ReviewLoopList";
import ResponseItem from "./ResponseItem";
import * as mutations from "../graphql/mutations";
import ResponseListItemActionContainer from "./ResponseListItemActionContainer";
import {Icon, Rating, Table} from "semantic-ui-react";
import moment from "moment";
import ResponseStatusButton from "./ResponseStatusButton";
import _ from "lodash";

const SORT_FIELDS = [
    {
        key: 'createdat',
        text: 'Date',
        value: 'createdAt'
    },
    {
        key: 'rating',
        text: 'Rating',
        value: 'rating'
    },
    {
        key: 'firstname',
        text: 'First Name',
        value: 'contact.firstName'
    },
    {
        key: 'lastname',
        text: 'Last Name',
        value: 'contact.lastName'
    }
];

const Details = (props) => {
    const {status} = props;
    let label = {};
    switch (status) {
        case 'new': label = {display: true, color: 'purple', icon: 'inbox'}; break;
        case 'archived': label = {display: true, color: 'red', icon: 'archive'}; break;
        case 'published': label = {display: true, color: 'blue', icon: 'world'}; break;
        default:
            label = {hide: false, color: 'orange', icon: 'inbox'}; break;
    }
    return (
        <>
            {
                label.display && <Icon style={{marginRight:5}} color={label.color} name={label.icon} />
            }
        </>
    )
};

const TableRow = (props) => {

    const { item, updateResponse } = props;

    const [ hover, setHover ] = useState(false);


    function onMouseEnter(){
        setHover(true);
    }

    function onMouseLeave() {
        setHover(false);
    }

    return (
        <Table.Row key={item.id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Table.Cell><Rating icon={"star"} rating={item.rating} maxRating={5} size={"huge"} disabled/></Table.Cell>
            <Table.Cell><span>{item.contact.firstName} {item.contact.lastName}</span></Table.Cell>
            <Table.Cell><span><i>{item.message}</i></span></Table.Cell>
            {
                hover && (
                    <Table.Cell colSpan='2'  textAlign='right' collapsing>
                        <ResponseStatusButton responseId={item.id} currentStatus={item.status} status="published" onUpdate={updateResponse} />
                        <ResponseStatusButton responseId={item.id} currentStatus={item.status} status="archived" onUpdate={updateResponse} />
                    </Table.Cell>
                )
            }
            {
                !hover && (
                    <>
                        <Table.Cell><Details status={item.status} createdAt={item.createdAt}/></Table.Cell>
                        <Table.Cell><span>{moment(item.createdAt).format("D MMM YYYY")}</span></Table.Cell>
                    </>
                )
            }
        </Table.Row>
    )
};

class ResponseListWithData extends Component {

    state = {
        responses: {},
        user: {},
        loading: true,
        loaded: false,
        errors: [],
        hover: false
    };

    handleClick = (e, {checked}) => {
        e.preventDefault();
        e.stopPropagation();
        const { onSelected, item } = this.props;
        onSelected(item, checked);
    };

    async componentDidMount(): void {
        await this.fetchResponses();
    }

    async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

        if (this.props.tab !== prevProps.tab) {
            this.setState({loaded: false});
            this.fetchResponses()
        }
    }

    fetchUser = async () => {
        const queryVariables = {
            id: (await Auth.currentSession()).getIdToken().payload['cognito:username']
        };

        try {
            const { data: { getUser: user } } = await API.graphql(graphqlOperation(queries.getUser, queryVariables));
            this.setState({user, loading: false, loaded: true });
        } catch (e) {
            console.error(e);
            this.setState({ errors: [e], loading: false, loaded: true });
        }
    };

    sortItems = (field, direction = 'DESC') => {
        const { responses } = this.state;
        let sorted = _.sortBy(responses, [field])
        if(direction === 'DESC') {
            sorted = _.reverse(sorted);
        }
        this.setState({ responses: sorted })
    };

    fetchResponses = async ({search, sortField, sortOrder = 'DESC'} = {}) => {
        let { tab } = this.props;

        this.setState({loading: true});

        const queryVariables = {
            limit: 1000,
            userID: (await Auth.currentSession()).getIdToken().payload['cognito:username'],
            sortDirection: sortOrder
        };

        tab = tab ? tab : 'new';

        if (tab !== 'all') {
            queryVariables['filter'] = { status : { eq: tab  }}
        }

        try {
            const { data: { responsesByUser } } = await API.graphql(graphqlOperation(queries.responsesByUser, queryVariables));
            const responses = responsesByUser && responsesByUser.items ? responsesByUser.items.reduce((map, obj) => ({ ...map, [obj.id]: obj}), {}) : {};
            this.setState({responses, loading: false, loaded: true });
        } catch (e) {
            console.error(e);
            this.setState({ errors: [e], loading: false, loaded: true });
        }
    };

    updateResponse = async (id, status) => {
        const { responses } = this.state;
        let response = responses[id];
        response.status = status;
        this.setState({responses});
        try {
            const input = { id, status };
            ({ data: { updateResponse : response }} = await API.graphql(graphqlOperation(mutations.updateResponse, {input})));
            responses[response.id] = response;
            this.setState({responses, loading: false, errors: [] });
            toast({
                type: 'success',
                icon: status === 'published' ? 'world' : 'archive',
                title: 'Success',
                description: `Review ${status}`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        } catch (e) {
            console.error(e);
            this.setState({ errors: [e], loading: false });
            toast({
                type: 'error',
                icon: status === 'published' ? 'world' : 'archive',
                title: 'Error',
                description: `Error ${status === 'published' ? 'publishing' : 'archiving'} review: ${response.id} - ${response.message}`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        }
        this.fetchResponses();
    };

    updateResponses = async (items, status) => {
        const { responses, errors } = this.state;

        let count = 0;
        for (const item of items) {
            let response = responses[item.id];
            response.status = status;
            try {
                const input = { id: item.id, status };
                await API.graphql(graphqlOperation(mutations.updateResponse, {input}));
                count++;
            } catch (e) {
                console.error(e);
                this.setState({ errors: [...errors, e]});
                toast({
                    type: 'error',
                    icon: status === 'published' ? 'world' : 'archive',
                    title: 'Error',
                    description: `Error ${status === 'published' ? 'publishing' : 'archiving'} review: ${response.id} - ${response.message}`,
                    animation: 'bounce',
                    time: 5000,
                    size: 'mini'
                });
            }
        }

        toast({
            type: 'success',
            icon: status === 'published' ? 'world' : 'archive',
            title: 'Success',
            description: `${status} ${count}/${items.length} reviews`,
            animation: 'bounce',
            time: 5000,
            size: 'mini'
        });
        await this.fetchResponses();
    };

    sortItems = (field, direction = 'DESC') => {
        const { responses } = this.state;
        let sorted = _.sortBy(responses, [field]);
        if(direction === 'DESC') {
            sorted = _.reverse(sorted);
        }
        this.setState({ responses : sorted })
    };


    render() {
        let { tab, navigate } = this.props;
        const {responses, loading, loaded, errors } = this.state;

        return (
            <ReviewLoopList
                reviewLoopListItem={ResponseItem}
                items={Object.values(responses)}
                loading={loading}
                loaded={loaded}
                errors={errors}
                itemActions={ResponseListItemActionContainer}
                onRefresh={this.fetchResponses}
                onSort={this.sortItems}
                sortFields={SORT_FIELDS}
                updateResponse={this.updateResponse}
                listActions={
                    [
                        tab !== 'published' && { icon: 'world', tooltip: 'Publish', onClick: (items, responses) => this.updateResponses(responses, 'published')},
                        tab !== 'archived' && { icon: 'archive', tooltip: 'Archive', onClick: (items, responses) => this.updateResponses(responses, 'archived')},
                    ]
                }

            />
            // <Table fixed singleLine size='large' basic='very' selectable>
            //     <Table.Body>
            //         {
            //             Object.values(responses).map((item) => (
            //                 <TableRow key={item.id} item={item} updateResponse={this.updateResponse}/>
            //             ))
            //         }
            //     </Table.Body>
            // </Table>
        )
    }
}

export { TableRow }
export default ResponseListWithData;
