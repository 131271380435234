import React, {Component} from 'react'
import {Checkbox, Divider, Dropdown, Icon, Menu, Pagination, Popup, Transition} from 'semantic-ui-react'
import ReviewLoopActionButton from "../ReviewLoopActionButton";
import _ from "lodash";



const SORT_ORDER = [
    {
        key: 'ASC',
        text: 'Asc',
        value: 'ASC'
    },
    {
        key: 'DESC',
        text: 'Desc',
        value: 'DESC'
    },
];

export default class ListMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sortField: props.defaultSort ? props.defaultSort.field : props.sortFields[0].value,
            sortOrder: props.defaultSort ? props.defaultSort.order : SORT_ORDER[1].value,
            activePage: 1,
            pageSize: 10
        };
    }

    componentDidMount(): void {
        this.handlePaginationChange(null, { activePage: this.state.activePage });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(!_.isEqual(prevProps.items, this.props.items)) {
            this.handlePaginationChange(null, { activePage: this.state.activePage });
        }
    }

    handleCheckboxClick = (e, {checked}) => {
        const { onSelectionChange } = this.props;

        e.preventDefault();
        e.stopPropagation();

        onSelectionChange(checked)
    };

    refresh = async () => {
        const { onRefresh } = this.props;
        this.setState({loading: true});
        onRefresh && await onRefresh();
        this.setState({loading: false});
    };

    sort = async (e, {name, value}) => {
        console.log(`${name}  ${value}`);
        const { onSort } = this.props;
        this.setState({loading: true, [name] : value}, () => {
            const { sortField, sortOrder } = this.state;
            if(onSort) onSort(sortField, sortOrder);
        });
        this.setState({loading: false});
    };

    isUndetermined = () => {
        const { selected } = this.props;
        return selected && !this.isChecked() && selected.length > 0
    };

    isChecked = () => {
        const { selected, items } = this.props;
        return selected && items && selected.length === items.length && items.length > 0
    };

    showActions = () => {
        const { actions } = this.props;
        return actions && actions.length > 0 && (this.isChecked() || this.isUndetermined());
    };

    handlePaginationChange = (e, { activePage } = { activePage: 1}) => {
        const { pageSize } = this.state;
        this.props.onPageSelected(activePage, pageSize);
        this.setState({ activePage})
    };

    render() {
        const { selected, items, actions, sortFields } = this.props;
        const { loading } = this.state;

        return  (
            <>
            <Menu style={{padding: "0px 4px", marginBottom:0}} secondary>
                <Menu.Item style={{paddingRight: 0}}>
                    <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content='Select All' trigger={
                        <Checkbox indeterminate={this.isUndetermined()} checked={this.isChecked()}
                                  onClick={this.handleCheckboxClick} disabled={items.length === 0}/>
                    } />
                    <span style={{marginLeft: 5}}>{selected.length !== 0 ? `${selected.length}/` : ''}{items.length} items</span>
                </Menu.Item>

                {
                    actions && actions.map( (action, index) => {
                        if(!action) return ;

                        const RenderToolTip = action.tooltipRender;
                        return (
                            <Transition key={`${action.icon}-${index}`} as={Menu.Item} reactKey={`${action.icon}-${index}`} fitted visible={this.showActions()} unmountOnHide duration={0}>
                                {
                                    !action.tooltipRender ?
                                        <Popup basic style={{opacity: 0.9}} inverted position='bottom center' size='mini'
                                               content={action.tooltip} trigger={
                                            <div>
                                                <ReviewLoopActionButton icon={action.icon} circular
                                                                        onClick={() => action.onClick(items, selected)}/>
                                            </div>
                                        }/>
                                        :
                                        <Popup basic style={{opacity: 0.9}} on='hover' inverted position='bottom center' size='mini'
                                               content={action.tooltip} trigger={
                                            <div>
                                                <Popup basic closeOnTriggerClick position='bottom right' on={['click']} trigger={
                                                    <div>
                                                        <RenderToolTip items={items} selected={selected} onClick={action.onClick} action={action} />
                                                    </div>
                                                }>
                                                    {/*<RenderToolTip items={items} selected={selected} onClick={action.onClick} />*/}
                                                </Popup>
                                            </div>
                                        } />
                                }
                            </Transition>
                        )})
                }
                {
                    !this.showActions() && (
                        <Menu.Item fitted>
                            <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content='Refresh' trigger={
                                <div>
                                    <ReviewLoopActionButton icon circular onClick={this.refresh}>
                                        <Icon name='refresh' loading={loading}/>
                                    </ReviewLoopActionButton>
                                </div>
                            } />
                        </Menu.Item>
                    )
                }

                <Menu.Menu position='right'>
                    {
                        this.props.items.length > 0 && (
                            <Menu.Item fitted='vertically'>
                                <Pagination
                                    defaultActivePage={1}
                                    boundaryRange={1}
                                    // ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={0}
                                    secondary
                                    totalPages={Math.ceil(this.props.items.length/10)}
                                    onPageChange={this.handlePaginationChange}
                                />
                            </Menu.Item>
                        )
                    }

                    <Menu.Item>
                        <span>Sort by &nbsp;<Dropdown inline options={sortFields} defaultValue={this.state.sortField} name='sortField' onChange={this.sort}/>&nbsp;<Dropdown inline options={SORT_ORDER} name={'sortOrder'} defaultValue={this.state.sortOrder} onChange={this.sort}/></span>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider fitted />
            </>
        )
    }
}
