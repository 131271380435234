import React, {useState} from "react";
import {Button, Header, Icon, Label, Popup, Segment} from "semantic-ui-react";
import copy from 'clipboard-copy';

// **** or ***** stars = share to Google Reviews
// *** = thanks for leaving a review
// * or ** = sorry to hear your experience was less than stellar. You’ll be hearing from one of our team members soon

function ResponseFormThankYou(props) {

    const [copied, setCopied] = useState(false);

    async function copyToClipboard() {
        const { message } = props;
        await copy(message);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
    }

    if(props.rating >= 4) {
        return (
            <Segment basic>
                <Header as='h2'>Share <Icon name='share alternate' /> </Header>
                <Header as='h4' >
                    Thank you for the positive review! We truly appreciate you!
                    If you would like to share your experience with Google Reviews, that would be outstanding! Click the button, bottom right, to copy the review to your clipboard.
                </Header>
                <Segment padded textAlign='left'>
                    <Label color={copied ? '' : 'purple'} attached='bottom right'>
                        <Popup basic style={{opacity:0.9}} on={['hover']} inverted position='bottom center' size='mini' content={copied ? 'Copied!' : 'Click to copy'} trigger={
                            <Icon link={!copied} disabled={copied} name='copy' onClick={copyToClipboard}/>
                        }/>
                    </Label>
                    {props.message}
                </Segment>

                <Button color='google plus' labelPosition='left' href='https://search.google.com/local/writereview?placeid=ChIJ7-GqJ0rHQIYRoZFDLzCy4jg'>
                    <Label basic><Icon name='google'/> Google </Label>
                    <Button color='google plus'>
                        <Icon name='share'/>
                    </Button>
                </Button>

            </Segment>
        )
    }

    return (
        <Segment basic>
            {
                    <>
                    <Header as='h4'>
                        Thank you for the review!
                    </Header>
                        <p>
                            We really value your input and experience. At LPC, we strive to provide a positive experience for every person that we work with. With this feedback, we will endeavor to improve our service!
                        </p>
                    </>
            }

        </Segment>
    )

}

export default ResponseFormThankYou;
