import React, {Component} from 'react';
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import ReviewLoopList from "./ReviewLoopList";
import GroupItem from "./GroupItem";
import {navigate} from "@reach/router";

const SORT_FIELDS = [
    {
        key: 'createdAt',
        text: 'Date',
        value: 'createdAt'
    },
    {
        key: 'name',
        text: 'Name',
        value: 'name'
    },
];

class ContactsGroupList extends Component {

    state = {
        items: [],
        errors: [],
        loading: true,
        loaded: false,
    };

    componentDidMount = async () => {
        this.fetchItems();
    };

    fetchItems = async () => {
        try {
            const { data : { listGroups } } = await API.graphql(graphqlOperation(queries.listGroups, {limit: 1000}));
            const items = listGroups ? listGroups.items : [];
            this.setState({ items, loading: false, loaded: true });
        } catch (e) {
            this.setState({ errors: [e], loading: false, loaded: true });
        }
    };

    deleteItems = async (groups) => {
        try {
            this.setState({loading: true});
            for (const group of groups) {
                const { data: { deleteGroup } } = await API.graphql(graphqlOperation(mutations.deleteGroup, {input: {id: group.id}}))
            }
            await this.fetchItems();
            this.setState({loading: false});
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { items, errors, loading, loaded } = this.state;

        return (
            <ReviewLoopList {...this.props}
                            items={items}
                            errors={errors}
                            loading={loading}
                            loaded={loaded}
                            reviewLoopListItem={GroupItem}
                            onRefresh={this.fetchItems}
                            onSort={this.sortItems}
                            sortFields={SORT_FIELDS}
                            listActions={
                                this.props.listActions ?
                                [
                                    { icon: 'send', tooltip: 'Request Review', onClick: (items, groups) => navigate("/requests", { state: {groups} })},
                                    { icon: 'trash alternate outline', tooltip: 'Delete', onClick:  (items, groups) => this.deleteItems(groups) },
                                ] : false
                            }
            />
        )
    }



}

export default ContactsGroupList;
