import React, {useState} from "react";
import {Button, Form, Icon, Modal} from "semantic-ui-react";
import useAccounts from "../../hooks/useAccounts";
import useUsers from "../../hooks/useUsers";

const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    userAccountId: '',
};

const UserForm = (props) => {
    const [ formData, setFormData ] = useState(initialState);

    const [ modalOpen, setModalOpen ] = useState(false);

    const [ , createUser, ] = useUsers([]);

    const [accounts, ] = useAccounts([]);

    function handleChange(e, { name, value }) {
        setFormData({ ...formData , [name]: value } )
    }

    async function handleSubmit() {
        await createUser(formData);
        setModalOpen(false);
        clearForm();
    }

    function clearForm() {
        setFormData( initialState)
    }

    let options = !accounts ? [] : accounts.map(account => ({key: account.id, text: account.name, value: account.id}));

    return (
        <Modal trigger={
            <Button
                icon
                labelPosition='left'
                color='purple'
                onClick={() => setModalOpen(true)}
            >
                <Icon name='user'/> Add User
            </Button>
        }
        open={modalOpen}
        onClose={() => setModalOpen(false)}>
            <Modal.Header>Add User</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input label="First Name" required name="firstName" value={formData.firstName} onChange={handleChange}/>
                        <Form.Input label="Last Name" required name="lastName" value={formData.lastName} onChange={handleChange}/>
                        <Form.Input label="Email" required name="email" value={formData.email} onChange={handleChange}/>
                        <Form.Input label="Phone" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
                        <Form.Dropdown label="Account" required selection options={options} placeholder="Select Account..." name='userAccountId' value={formData.userAccountId} onChange={handleChange}/>
                        <Form.Button>Submit</Form.Button>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
};

export default UserForm;
