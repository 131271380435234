import {useEffect, useState} from "react";

import {API, graphqlOperation, Auth} from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

const useSESSettings = () => {

    const [sesSettings, setSesSettings] = useState({
            id: null,
            email: '',
            status: null
    });

    useEffect(() => {
            getSesSettings();
    },[]);

    async function getSesSettings() {
        const { data : { listSESs } } = await API.graphql(graphqlOperation(queries.listSeSs, {userId: { eq : (await Auth.currentAuthenticatedUser()).username }}));
        if (listSESs && listSESs.items.length > 0) {
            setSesSettings({...listSESs.items[0]})
        }
    }

    async function createSesSettings(email) {
        const { data: { createSES : ses } } = await API.graphql(graphqlOperation(mutations.createSes, { input: { email, userID: (await Auth.currentAuthenticatedUser()).username }}));
        setSesSettings(ses)
    }

    async function updateSesSettings(email) {
        const { data: { updateSES : ses } } = await API.graphql(graphqlOperation(mutations.updateSes, { input: { id: sesSettings.id, email }}));
        setSesSettings(ses)
    }

    return [sesSettings, createSesSettings, updateSesSettings, getSesSettings]
}

export default useSESSettings;
