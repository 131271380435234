// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:552e06ef-4c6d-460b-8482-66d9d42692b3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BMPrrQCSH",
    "aws_user_pools_web_client_id": "1lfj9tujqirh99f7noh96gtvb3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://z7cexzjzxvcqbbr2woubjbmsde.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-swuciy7bybbmvk347h3g6jbg2m",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://kh3xu71n21.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "emailapi",
            "endpoint": "https://lp6m7xhvm4.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "reviewloopReviewsAPI",
            "endpoint": "https://iah6nk9iae.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "email",
            "endpoint": "https://j5d87oujq5.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "reviewloop-20190912235009-hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://reviewloop-20190912235009-hostingbucket-master.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "reviewloop00ca8a2bbccb4d9f8659c5a510f723bd-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
