import React from "react";
import { Table } from 'semantic-ui-react';
import useUsers from "../../hooks/useUsers";

const Users = (props) => {

    const [ users ] = useUsers();

    return (
        <>
            <Table celled compact definition singleLine>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Account</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>First Name</Table.HeaderCell>
                        <Table.HeaderCell>Last Name</Table.HeaderCell>
                        <Table.HeaderCell>Phone</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        users.map(user => {
                            return (
                                <Table.Row key={user.id}>
                                    <Table.Cell>{ user.account.name }</Table.Cell>
                                    <Table.Cell>{ user.email }</Table.Cell>
                                    <Table.Cell>{ user.firstName }</Table.Cell>
                                    <Table.Cell>{ user.lastName }</Table.Cell>
                                    <Table.Cell>{ user.phoneNumber  }</Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        </>
    )
};

export default Users;
