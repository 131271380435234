import React, {useEffect, useRef, useState} from "react";
import _ from 'lodash';
import {Button, Divider, Form, Header, Icon, Input, Segment} from "semantic-ui-react";
import {API} from "aws-amplify";
import {graphqlOperation} from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import GroupContactsList from "./GroupContactsList";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import ContactsListSegment from "./ContactsListSegment";
import ContactsList from "./ContactsList";
import {updateContactGroup} from "../graphql/mutations";

function GroupForm({groupID, location, navigate}) {

    const [ editName, setEditName] = useState(groupID === 'add');
    const [modalOpen, setModalOpen] = useState(false);

    const [group, setGroup] = useState({name: [], contacts: { items: [] }});
    const [groupContacts, setGroupContacts ] = useState([]);
    const [name, setName] = useState(location.state.name || '');
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [ error, setError] = useState(false);


    const [selectedContacts, setSelectedContacts] = useState( []);


    const inputRef = useRef(null);


    useEffect(() => {
        if (!editName && groupID) {
            setGroupContacts([]);
            setSelectedContacts([]);
            // setEditName(groupID === 'add');
            setName("");
            fetchGroup();
        }

        if (editName) {
            inputRef.current.focus();
            setLoading(false);
            setLoaded(true);
        }

    }, [groupID, editName]);

    async function handleSubmit() {
        if(groupID === 'add') {
            await createGroup()
        } else {
            await updateGroup()
        }

        setEditName(false);
        await fetchGroup();

    }

    async function handleAddContacts () {
        const contactGroup = group.contacts.items;
        const allContacts = contactGroup.map(groupContact => groupContact.contact);
        const filteredContacts = contactGroup.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted).map(groupContact => groupContact.contact);
        const toBeDeleted = _.differenceBy(filteredContacts, selectedContacts, 'id');
        const toBeAdded = _.differenceBy(selectedContacts, allContacts, 'id');
        const toBeUpdated = _.differenceBy(_.differenceBy(selectedContacts, filteredContacts, 'id'), toBeAdded, 'id');

        for (const contact of toBeDeleted) {
            const deletable = _.filter(contactGroup, (groupContact) =>
                (!contact && !groupContact.contact)
                || (contact && groupContact.contact && groupContact.contact.id === contact.id)
            );
            for (const contactGroup of deletable) {
                await deleteGroupContact(contactGroup.id)
            }
        }

        for (const contact of toBeAdded) {
            await createGroupContact(contact.id)
        }

        for (const contact of toBeUpdated) {
            const updatable = _.filter(contactGroup, (groupContact) =>
                (!contact && !groupContact.contact)
                || (contact && groupContact.contact && groupContact.contact.id === contact.id)
            );
            for (const contactGroup of updatable) {
                await updateGroupContact(contactGroup.id)
            }
        }

        setModalOpen(false);

        await fetchGroup();
    }

    async function createGroup() {
        const { data: {createGroup}} = await API.graphql(graphqlOperation(mutations.createGroup, {input: {name}}));
        setState(createGroup);
        setEditName(false);
        navigate(`../${createGroup.id}`)

    }

    async function updateGroup() {
        const { data: {updateGroup}} = await API.graphql(graphqlOperation(mutations.updateGroup, {input: {id: group.id, name}}));
        setGroup(updateGroup)
    }

    async function deleteGroupContact(contactGroupId) {
        const { data: {deleteContactGroup: contactGroup}} = await API.graphql(graphqlOperation(mutations.updateContactGroup, {input: { id: contactGroupId, deleted: true}}));
    }

    async function createGroupContact(contactId) {
        const { data: {createContactGroup: contactGroup}} = await API.graphql(graphqlOperation(mutations.createContactGroup, {input: {contactGroupContactId: contactId, contactGroupGroupId: group.id}}));
    }

    async function updateGroupContact(contactGroupId) {
        const { data: {deleteContactGroup: contactGroup}} = await API.graphql(graphqlOperation(mutations.updateContactGroup, {input: { id: contactGroupId, deleted: false}}));
    }

    async function onContactsSelected(selectedContacts) {
        setSelectedContacts(selectedContacts)
    }

    function setState(group) {
        setGroup(group);
        setName(group.name);
        setGroupContacts(group.contacts.items.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted));
        setSelectedContacts(group.contacts.items.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted).map(groupContact => groupContact.contact))
    }

    async function fetchGroup() {
        setLoading(true);
        const {data: {getGroup: group}} = await API.graphql(graphqlOperation(queries.getGroup, {id: groupID}));
        if (group) {
            setState(group);

        }
        setLoaded(true);
        setLoading(false);
    }

    function validateName() {
        if((groupID === 'add' && name === "") || group.name === name) {
            inputRef.current.focus();
            setError(true);
        }
        else setError(false);
    }

    return (
        <Segment basic >
            <Header as='h2'>{
                editName ? (
                    <>
                        <Input onBlur={validateName} ref={inputRef} error={error} transparent width={4} placeholder='Group Name' value={name} onChange={(e, {value}) => setName(value)} />
                        <Button disabled={group.name === name || name === ""} onClick={handleSubmit}>Save</Button>
                        {groupID !== 'add' && <Button onClick={() => {setEditName(false); setError(false)}}>Cancel</Button>}
                    </>
                ) :
                    <>
                        {name}
                        <span onClick={() => setEditName(true)} style={{fontSize: '.5em', color: 'grey', cursor: 'pointer', marginLeft: 5}}>(edit)</span>
                        <Modal open={modalOpen} onClose={() => setModalOpen(false)} trigger={
                            <Button floated="right" onClick={() => setModalOpen(true)}>
                                <Icon.Group>
                                    <Icon name='group' />
                                </Icon.Group>
                                Add/Remove Contacts</Button>}>
                            <Modal.Header>Edit Contacts</Modal.Header>
                            <Modal.Content image>
                                <Modal.Description>
                                    <ContactsList
                                        listActions={false}
                                        selected={selectedContacts}
                                        onItemSelectionChange={onContactsSelected}
                                        itemActions={false}
                                    />
                                </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={handleAddContacts}>Add/Remove Contacts</Button>
                            </Modal.Actions>
                        </Modal>

                    </>
            }</Header>
            <GroupContactsList
                listActions={true}
                loaded={loaded}
                loading={loading}
                items={groupContacts}
                refresh={fetchGroup}
                onItemSelectionChange={onContactsSelected} />

        </Segment>
    )
}

export default GroupForm;
