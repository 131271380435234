import React, {Component} from 'react';
import {Menu} from "semantic-ui-react";
import {Link, Router} from "@reach/router";
import _ from 'lodash/string'

import ContactsList from "./ContactsList";
import ContactsGroupList from "./ContactsGroupList";
import ContactsForm from "./ContactsForm";
import GroupForm from "./GroupForm";

class Contacts extends Component {

    state = { contacts : true, groups: false, imports: false };

    isActive = (name, isCurrent) => {
        if (this.state[name] !== isCurrent) {
            this.setState({ [name] : isCurrent })
        }
    };


    render() {
        const { contacts, groups, imports } = this.state;
        return (
            <div>
                <Menu pointing secondary style={{margin: 0}}>
                    <Link getProps={({isCurrent, location}) => this.isActive('contacts', isCurrent || _.endsWith(location.pathname, 'contacts/new'))} to="./" >
                        <Menu.Item icon="user" name='Contacts' active={contacts} />
                    </Link>
                    <Link getProps={({isPartiallyCurrent}) => this.isActive('groups', isPartiallyCurrent)} to="groups" >
                        <Menu.Item icon="group" name='Groups' active={groups}/>
                    </Link>
                    <Link getProps={({isPartiallyCurrent}) => this.isActive('imports', isPartiallyCurrent)} to="import" >
                        <Menu.Item icon="file alternate" name='Import' active={imports} />
                    </Link>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            {/*<Input*/}
                            {/*    transparent*/}
                            {/*    icon={{ name: 'search', link: true }}*/}
                            {/*    placeholder='Search...'*/}
                            {/*/>*/}
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

                <Router>
                    <ContactsGroupList path="groups" listActions />
                    <GroupForm path="groups/:groupID" />
                    <ContactsForm path="import/*" />
                    <ContactsList path="/*" listActions={true} />
                </Router>
            </div>
        );

    }

}

export default Contacts
