import React, {useState} from "react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import {Container, Grid, Rating} from "semantic-ui-react";
import moment from "moment";

function ResponseDetail(props) {

    const [review, setReview] = useState(props.location.state);

    const {rating, createdAt, contact: { firstName, lastName, phone, email }, message} = review;

    return (
        <Grid style={{paddingTop: 50}} centered columns={2}>
            <Grid.Column>
                <Card fluid>
                    <Card.Content textAlign='center'>
                        <br/>
                        <Rating maxRating={5} rating={rating} disabled icon='star' size='massive' />
                        <br/>
                        <br/>
                        <Card.Header>{message}</Card.Header>
                        <Card.Meta>
                            <span className='moment(createdAt)'>{moment().diff(moment(createdAt), 'hours') <= 9 ? moment(createdAt).fromNow() : moment(createdAt).isSame(moment(), 'day') ? moment(createdAt).format("D MMM H:m A") : moment(createdAt).isSame(moment(), 'year') ? moment(createdAt).format("D MMM") : moment(createdAt).format("D/MM/YYYY")}</span>
                        </Card.Meta>
                        <Card.Description>
                            <b>{firstName} {lastName}</b><br/>
                            {email}<br/>
                            {phone}
                        </Card.Description>

                        <br/>
                    </Card.Content>
                    {/*<Card.Content extra>
                        <a>
                            <Icon name='user' />
                            22 Friends
                        </a>
                    </Card.Content>*/}
                </Card>
            </Grid.Column>
        </Grid>
    )
}

export default ResponseDetail;
