import React, { useState, useEffect, useRef } from "react";
import {Storage, API, graphqlOperation} from "aws-amplify";
import uuid from 'uuid/v4';

import config from '../../aws-exports'

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import {Button, Dimmer, Grid, Icon, Image, Input, Placeholder, Segment} from "semantic-ui-react";
import useUser from "../../hooks/useUser";

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;



function AccountForm(props) {

    const [file, updateFile] = useState(null);

    const [ loading, setLoading ] = useState(true);
    const [logo, setLogo] = useState("");
    const [name, setName] = useState("");
    const [googleReviewUrl, setGoogleReviewUrl] = useState('');
    const [imageHovered, setImageHovered] = useState(false);

    const [ account, setAccount ] = useState(null);

    const [user] = useUser();

    const imageSelectorRef = useRef(null);

    useEffect(() => {
        if (user) {
            getAccount();
        }
    }, [user]);


    async function getAccount() {
        const { data: { getAccount : account }} = await API.graphql(graphqlOperation(queries.getAccount,  {id: user.account.id}));
        setAccount(account);
        account.logo && await fetchImage(account.logo.key);
        account.name && setName(account.name);
        setGoogleReviewUrl(account.googleReviewUrl || "");
        setLoading(false);
    }

    async function fetchImage(key) {
        try {
            const imageData = await Storage.get(key);
            setLogo(imageData)
        } catch(err) {
            console.log('error: ', err)
        }
    }

    async function updateAccountLogo(file) {
        console.log(file)
        const { name: fileName, type: mimeType } = file;
        const key = `${uuid()}-${fileName}`;
        const s3File = {
            bucket,
            key,
            region,
        };
        setLoading(true);

        const inputData = {id: account.id, logo: s3File };

        try {
            await Storage.put(key, file, {
                contentType: mimeType
            });
            await API.graphql(graphqlOperation(mutations.updateAccount, { input: inputData }));
            console.log('successfully stored user data!')
        } catch (err) {
            console.log('error: ', err)
        }
    }

    async function updateAccount() {
        const inputData = {id: account.id, name, googleReviewUrl };
        try {
            await API.graphql(graphqlOperation(mutations.updateAccount, { input: inputData }));
            console.log('successfully stored user data!')
        } catch (err) {
            console.log('error: ', err)
        }
    }


    async function handleLogoChange(event) {
        const [image] = imageSelectorRef.current.files || [];
        console.log(image);
        updateFile(image || file);
        await updateAccountLogo(image);
        await getAccount();
    }

    function handleCompanyNameChange(event, {value}) {
        setName(value);
    }

    function handleGoogleReviewURLChange(e, {value}) {
        setGoogleReviewUrl(value);
    }

    const widths = [2, 4];

    return (
        <Segment basic clearing>
            <Grid verticalAlign='middle' centered>
                <Grid.Row>
                    <Grid.Column width={widths[0]}>
                        Logo
                    </Grid.Column>
                    <Grid.Column width={widths[1]}>
                        <Dimmer.Dimmable as={Segment}
                                         floated='left' style={{height: 60, width: 150, padding: 0}} compact
                                         dimmed={imageHovered}
                                         onMouseEnter={() => setImageHovered(true)}
                                         onMouseLeave={() => setImageHovered(false)}
                        >
                            <Dimmer active={imageHovered}>
                                <Icon name='pencil' size='large' link onClick={() => imageSelectorRef.current.click()}/>
                                <div>150 x 60</div>
                                <input type="file" id='logo-select' ref={imageSelectorRef} style={{display: 'none'}} onChange={handleLogoChange}/>
                            </Dimmer>
                            {
                                loading ?
                                    <Placeholder style={{ height: 60, width: 150 }}>
                                        <Placeholder.Image />
                                    </Placeholder>
                                    :
                                    <Image
                                        height={60}
                                        width={150}
                                        src={logo}
                                    />
                            }
                        </Dimmer.Dimmable>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={widths[0]}>
                        Company Name
                    </Grid.Column>
                    <Grid.Column width={widths[1]}>
                        <Input value={name} onChange={handleCompanyNameChange}/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={widths[0]}>
                        Google Reviews URL
                    </Grid.Column>
                    <Grid.Column width={widths[1]}>
                        <Input value={googleReviewUrl} onChange={handleGoogleReviewURLChange}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Button floated='right' icon='save' onClick={updateAccount} />

        </Segment>
    )
}

export default AccountForm;
