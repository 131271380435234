import React, {Component} from 'react';
import {Checkbox, List, Loader, Placeholder} from 'semantic-ui-react'
import _ from 'lodash'

import ListMenu from "./menus/ListMenu";


class ReviewLoopList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: props.show || [],
            selected: props.selected || [],
            messages: props.messages || []
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(!_.isEqual(prevProps.selected, this.props.selected)) {
            this.setState({selected: this.props.selected})
        }
    }

    handleSelected = (item, select) => {
        const { onItemSelectionChange } = this.props;
        let { selected } = this.state;

        if (select) {
            selected.push({...item});
        } else {
            selected.splice(selected.findIndex(obj => obj.id === item.id), 1)
        }

        this.setState({selected});

        if (onItemSelectionChange) {
            onItemSelectionChange(selected);
        }
    };

    onSelectionChange = (status) => {
        const { items, onItemSelectionChange } = this.props;
        this.setState({ selected: status ? [...items] : [] });
        if (onItemSelectionChange) {
            onItemSelectionChange(status ? [...items] : []);
        }
    };

    onPageSelected = (page, numItems) => {
        const { items } = this.props;
        const start = (page - 1) * numItems, end = start + numItems;
        const show = items.slice(start, end);
        this.setState({ show })
    }


    render() {
        const { items, errors, loading, loaded, reviewLoopListItem: ReviewLoopListItem, listActions } = this.props;
        const { selected , show} = this.state;

        let body;

        if (errors.length !== 0) {
            console.log(errors);
            body = (<h3>An error occurred fetching the data</h3>);
        }
         else if (loaded && items.length === 0) {
            body = (<h3>No Results</h3>);
        } else {
            body = (
                <>
                    <Loader active={loading} />
                    <List style={{marginTop:0}} selection relaxed='very' divided verticalAlign='middle' size='huge'>
                        {
                            loaded ?
                                show.map((item, index) =>
                                    <ReviewLoopListItem {...this.props} key={item.id || index} isSelected={selected.findIndex(i => i.id === item.id) !== -1} item={item} onSelected={this.handleSelected}/>)
                                :
                                [0,0,0,0,0,0,0,0].map((item, i) => (
                                    <List.Item key={i}>
                                        <List.Content >
                                            <List.Header className="left floated">
                                                <Checkbox style={{marginLeft: 7, marginRight: 10 }} disabled />
                                            </List.Header>
                                        </List.Content>
                                        <List.Content style={{paddingTop: 4}} className="left content">
                                            <Placeholder >
                                                <Placeholder.Header>
                                                    <Placeholder.Line length='full'/>
                                                </Placeholder.Header>
                                            </Placeholder>
                                        </List.Content>


                                    </List.Item>
                                ))
                        }
                    </List>
                </>
            );
         }

        return (
            <>
                <ListMenu {...this.props} items={items} actions={listActions} selected={selected} onSelectionChange={this.onSelectionChange} onPageSelected={this.onPageSelected} />
                { body }
            </>
        )
    }
}


export default ReviewLoopList
