import React, {useState} from "react";
import {Button, Divider, Form, Header, Icon, Modal} from "semantic-ui-react";
import useAccounts from "../../hooks/useAccounts";
import useUsers from "../../hooks/useUsers";

const initialState = {
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    userAccountId: '',
};

const AccountForm = (props) => {
    const [ formData, setFormData ] = useState(initialState);

    const [ modalOpen, setModalOpen ] = useState(false);

    const [ , createAccount,  ] = useAccounts([]);

    const [, createUser, , ] = useUsers([]);

    function handleChange(e, { name, value }) {
        setFormData({ ...formData , [name]: value } )
    }

    async function handleSubmit() {
        const account = await createAccount(formData.name);
        if (account) {
            await createUser({...formData, userAccountId: account.id, isOwner: true});
        }
        setModalOpen(false);
        clearForm();
    }

    function clearForm() {
        setFormData( initialState)
    }

    return (
        <Modal trigger={
            <Button
                icon
                labelPosition='left'
                color='purple'
                onClick={() => setModalOpen(true)}
            >
                <Icon name='building'/> Add Account
            </Button>
        }
        open={modalOpen}
        onClose={() => setModalOpen(false)}>
            <Modal.Header>Add Account</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form onSubmit={handleSubmit}>
                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='building' />
                                Account
                            </Header>
                        </Divider>
                        <Form.Input label="Company Name" required name="name" value={formData.name} onChange={handleChange}/>
                        <br/>
                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='user' />
                                Owner
                            </Header>
                        </Divider>
                        <Form.Group widths="equal">
                            <Form.Input label="First Name" required name="firstName" value={formData.firstName} onChange={handleChange}/>
                            <Form.Input label="Last Name" required name="lastName" value={formData.lastName} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Input label="Email" required name="email" value={formData.email} onChange={handleChange}/>
                        <Form.Input label="Phone" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
                        <Form.Button>Submit</Form.Button>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )


};

export default AccountForm;
