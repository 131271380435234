import React, {useState} from "react";
import {Grid, Input, Loader, Segment} from "semantic-ui-react";

import copy from "clipboard-copy";
import useUser from "../../hooks/useUser";

function IntegrationsForm() {

    const [, setCopied] = useState(false);
    const [ user ] = useUser();

    async function copyToClipboard(value) {
        await copy(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
    }


    if(!user) {
        return <Loader />
    }

    return (
        <Segment textAlign='center' basic clearing>
            <Grid centered>
                <Grid.Row centered columns={2}>
                    <Input input={<input disabled value={'da2-ds4wmakvu5ca5pdmrf2jo5rmbu'}/>}
                           action={{
                               color: 'purple',
                               icon: 'copy',
                               onClick: () => copyToClipboard('da2-ds4wmakvu5ca5pdmrf2jo5rmbu')
                           }}
                           label='Access Key'
                    />
                </Grid.Row>

                <Grid.Row centered columns={2}>
                    <Input input={<input disabled value={user ? user.account.id : null}/>}
                           action={{
                               color: 'purple',
                               icon: 'copy',
                               onClick: () => copyToClipboard(user ? user.account.id : null)
                           }}
                           label='Secret Key'
                    />
                </Grid.Row>

            </Grid>
        </Segment>
    )
}

export default IntegrationsForm;
