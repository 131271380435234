import { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";


function useAccounts({initialAccounts = [], users}) {
    const [ loading, setLoading ] = useState(false);
    const [ accounts, setAccounts ] = useState(initialAccounts);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            await getAccounts();
            setLoading(false);
        }
        fetch();
    }, [users]);


    async function getAccounts() {
        const { data: { listAccounts : { items: accounts } }} = await API.graphql(graphqlOperation(queries.listAccounts, {input: {limit: 1000 }}));
        setAccounts(accounts);
    }

    async function createAccount(name) {
        try {
            for (let account of accounts) {
                if (account.name === name) {
                    throw new Error("Account name must be unique")
                }
            }

            const inputData = { name: name };

            const { data: { createAccount : account }} = await API.graphql(graphqlOperation(mutations.createAccount, { input: inputData }));
            setAccounts([...accounts, account]);
            return account;
        } catch (err) {
            console.log('error: ', err)
        }
    }

    return [accounts, createAccount, loading];
}

export default useAccounts;
