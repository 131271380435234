import React, { Component } from 'react';

import {Redirect, Router} from "@reach/router"

import './App.css';

import Amplify  from 'aws-amplify';
import { withAuthenticator, ConfirmSignIn, ForgotPassword, RequireNewPassword } from 'aws-amplify-react';

// Components
import {Container, Grid} from 'semantic-ui-react'
import SignIn from './components/SignIn';
import TopMenu from './components/menus/TopMenu';
import SideMenu from './components/menus/SideMenu';
import Contacts from "./components/Contacts";
import ReviewsPage from "./components/ReviewsPage";
import ResponsesPage from "./components/ResponsesPage";
import { SemanticToastContainer } from 'react-semantic-toasts';


import awsconfig from './aws-exports';
import ResponseForm from "./components/ResponseForm";
import Settings from "./components/settings/Settings";
import Admin from "./components/admin/Admin";

// Amplify init

Amplify.configure(awsconfig);

class App extends Component {
    render() {
        return (
            <Container>
                <SemanticToastContainer position="top-center" />
                <TopMenu/>
                <Grid>
                    <Grid.Row stretched>
                        <Grid.Column floated='left' width={1}>
                            <SideMenu/>
                        </Grid.Column>
                        <Grid.Column width={15}>
                            <Router>
                                <ResponsesPage path="reviews/*"/>
                                <Contacts path="contacts/*"/>
                                <ReviewsPage path="requests/*"/>
                                <Settings path="settings/*"/>
                                <Admin path="admin/*"/>
                                <Redirect from="/" to="reviews" noThrow />
                            </Router>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const AppWithAuth = withAuthenticator(App, {
    usernameAttributes: 'email',
    authenticatorComponents: [
        <SignIn/>,
        <ConfirmSignIn/>,
        <ForgotPassword/>,
        <RequireNewPassword/>
    ],
    theme: {
        toast: { 'backgroundColor': '#800080' },
        button: { 'backgroundColor': '#800080' },
        a: { 'color': '#800080'}
    }
});

const ReviewsLoop = () => (
    <Router>
        <ResponseForm path="/r/:reviewContactId/:rating" />
        <AppWithAuth path="/*" />
    </Router>
);

export default ReviewsLoop
