import React, {Component} from 'react';
import {Checkbox, List} from 'semantic-ui-react'
import {Link} from "@reach/router";


export default class GroupItem extends Component {


    handleClick = (e, {checked}) => {
        e.preventDefault();
        e.stopPropagation();
        const { onSelected, item } = this.props;
        onSelected(item, checked);
    };


    render() {
        let {item, isSelected, onClick} = this.props;

        const linkProps = onClick ? {onClick: () => onClick(item)} : {as: Link, to: item.id};

        return (
            <List.Item {...linkProps}>
                <List.Content floated='right'>
                </List.Content>
                <List.Content floated='left'>
                    <List.Header className='left floated'><Checkbox style={{marginLeft:7, marginRight:10}} checked={isSelected}  onClick={this.handleClick}/>{item.name}</List.Header>
                </List.Content>
            </List.Item>
        )
    }


}


