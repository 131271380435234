import React, {Component} from "react";
import {Form, Grid, Rating, Segment} from "semantic-ui-react";
import Logo from "./Logo";
import {API, graphqlOperation, Auth, Storage} from "aws-amplify";

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import ResponseFormThankYou from "./ResponseFormThankYou";


class ResponseForm extends Component {

    constructor(props) {
        super(props);
        this.state = { rating: props.rating, message: '', submitted: false, account: null, logo: null, loading: true};
    }

    componentDidMount = async () => {
        this.setState({loading: true});
        const { reviewContactId, rating } = this.props;
        const { review: { id: responseReviewId, user }, contact: { id: responseContactId, firstName, lastName, responses }} = await this.getReviewContact(reviewContactId);
        this.getAccount(user.account.id);

        let submitted = false;
        for(let response of responses.items){
            if (response.review.id === responseReviewId && response.contact.id === responseContactId)
            {
                submitted = true;
                this.setState({...response, submitted: true});
                break;
            }
        }
        if (!submitted) {
            const response = await this.createResponse(responseReviewId, user.id, user.account.id, responseContactId, rating);
            this.setState({ ...response });
            try {
                await API.post('emailapi', '/items/send', {headers: {Authorization: `sNPz1Obajz3h8WKroRxfW7hZSdbp6QLy47PpSKvn`},
                    body: {
                        to: user.email,
                        from: 'ReviewsLoop Notification <notifications@reviewsloop.com>',
                        subject: `ReviewsLoop: New Review from ${firstName} ${lastName}`,
                        body: `<p>You have just received a new review</p><p>Click <a href="${window.location.origin}/reviews">ReviewsLoop Portal</a></p>`
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
    };

    getReviewContact = async (reviewContactId) => {
        try {
            const {data: {getReviewContact}} = await API.graphql({
                query: queries.getReviewContact,
                variables: {id: reviewContactId},
                authMode: 'AWS_IAM'
            });
            console.log(getReviewContact);
            return getReviewContact;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    getAccount = async (accountId) => {
        const { data: { getAccount : account }} = await API.graphql({
            query:queries.getAccount,
            variables: {id: accountId },
            authMode: 'AWS_IAM'
        });
        account.logo && await this.fetchImage(account.logo.key);
        this.setState({account})
    };

    fetchImage = async (key) => {
        try {
            const imageData = await Storage.get(key);
            this.setState({logo: imageData, loading: false})
        } catch(err) {
            console.log('error: ', err)
        }
    };

    createResponse = async (responseReviewId, userID, accountID, responseContactId, rating) => {
        try {
            const {data: {createResponse}} = await API.graphql({
                query: mutations.createResponse,
                variables: {
                    input: {
                        rating,
                        responseContactId,
                        responseReviewId,
                        userID,
                        accountID,
                        status: 'new'
                    }
                },
                authMode: 'AWS_IAM'
            });
            return createResponse;
        } catch(e) {
            console.error(e);
            throw e;
        }
    };

    updateResponse = async (id, rating, message) => {
        const { data: { updateResponse } } = await API.graphql({
            query: mutations.updateResponse,
            variables: {
                input: {
                    id,
                    message,
                    rating
                }
            },
            authMode: 'AWS_IAM'
        });
        return updateResponse;
    };

    handleSubmit = async () => {
        const { id, rating, message } = this.state;
        const response = await this.updateResponse(id, rating, message);
        this.setState({ ...response, submitted: true });
    };

    render() {
        const { rating, message, logo, loading } = this.state;
        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 700 }} textAlign='center'>
                    <Logo src={logo} size='medium' loading={loading} />
                    <br/>
                    <Segment raised>
                        { this.state.submitted ?
                            <ResponseFormThankYou {...this.state}/>
                            :
                            <Form>
                                <br/>
                                <Rating maxRating={5} defaultRating={rating} onRate={(e, {rating}) => this.setState({rating})} icon='star' size='massive'/>
                                <br/>
                                <br/>
                                <br/>
                                <Form.TextArea placeholder='Write your review...' value={message || ''}
                                               onChange={(e, {value}) => this.setState({message: value})} rows={4}/>
                                <br/>
                                <Form.Button onClick={this.handleSubmit}>Submit</Form.Button>
                            </Form>
                        }
                    </Segment>
                    <b>Powered By ReviewsLoop</b>
                    <p><a href="https://toptalentcom.com/">TopTalent Communication</a></p>
                </Grid.Column>
            </Grid>
        )
    }


}

export default ResponseForm;
