import React  from "react";
import {Header, Icon, Popup, Segment, Table} from 'semantic-ui-react';
import useAccounts from "../../hooks/useAccounts";
import AccountForm from "./AccountForm";
import UserForm from "./UserForm";
import useUsers from "../../hooks/useUsers";

const Accounts = () => {

    const [users, ,deleteUser, ] = useUsers([]);
    const [ accounts ] = useAccounts({users});


    return (
        <>
            <AccountForm />

            <UserForm />

            <Table compact celled structured singleLine>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Account</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign='center'>Owner</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                        {
                            accounts.map(account => {
                                const users = account.users.items;
                                if (users.length === 0) {
                                    return (
                                        <Table.Row key={account.id}>
                                            <Table.Cell rowSpan={1}>
                                                <Header as='h3'>
                                                    {account.name}
                                                    {/*<Segment basic compact size='small' floated='right'>*/}
                                                    {/*    <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content="Add User" trigger={*/}
                                                    {/*        <Icon link name='plus' color='purple'/>*/}
                                                    {/*    }/>*/}
                                                    {/*</Segment>*/}
                                                </Header>

                                            </Table.Cell>
                                            <Table.Cell collapsing textAlign='center'/>
                                            <Table.Cell> </Table.Cell>
                                        </Table.Row>
                                    )
                                }

                                return (
                                        users.map((user,i) => {
                                            return (
                                                <Table.Row key={user.id}>
                                                    {
                                                        (i === 0) &&
                                                        <Table.Cell rowSpan={ users.length } verticalAlign='top'>
                                                            <Header as='h3'>
                                                                {account.name}
                                                                {/*<Segment basic compact size='small' floated='right'>*/}
                                                                {/*    <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content="Add User" trigger={*/}
                                                                {/*        <Icon link name='plus' color='purple'/>*/}
                                                                {/*    }/>*/}
                                                                {/*</Segment>*/}
                                                            </Header>
                                                        </Table.Cell>
                                                    }
                                                    <Table.Cell collapsing textAlign='center'>{user.isOwner && <Icon name={"check"}/> }</Table.Cell>
                                                    <Table.Cell>
                                                        <span style={{marginRight: 5}}>{ user.email }</span>
                                                        <Popup basic style={{opacity:0.9}} inverted position='bottom center' size='mini' content="Delete" trigger={
                                                            <Icon link name='minus circle' color='red' onClick={async () => await deleteUser(user)}/>
                                                        }/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                )
                            })
                        }
                </Table.Body>
            </Table>
        </>
    )
};

export default Accounts;
