import React, {Component} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Input} from "semantic-ui-react";


class
EmailEditor extends Component {

    constructor(props) {
        super(props);

        let contentBlock;
        if (props.body) {
            contentBlock = htmlToDraft(props.body);
        } else {
            contentBlock = htmlToDraft('<p>Thank you for choosing us. We would love to know your experience of our services.</p><p>Click one of the stars below to leave a review...</p>')
        }

        let editorState;
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);

        }

        this.state = {
            editorState,
            subject: props.subject || ''
        };

        this.setEditorRef = (ref) => {
            this.editorRef = ref;
            if (ref) ref.focus();
        }
    }

    onSubjectChange = (e, {value}) => {
        const { onSubjectChange } = this.props;
        this.setState({subject: value});
        onSubjectChange(value);
    };

    onEditorStateChange: Function = (editorState) => {
        const { onChange } = this.props;
        this.setState({
            editorState,
        });
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };



    render() {
        const { editorState, subject } = this.state;
        return (
            <>
                <Input fluid defaultValue={subject} onChange={this.onSubjectChange} />
                <br/>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    editorRef={this.setEditorRef}
                />
            </>
        );
    }
}


export default EmailEditor;
