import React, {Component} from "react";

import ResponseStatusButton from "./ResponseStatusButton";

class ResponseListItemActionContainer extends Component {

    render() {
        const { item: { id, status }, updateResponse } = this.props;
        return (
            <>
                <ResponseStatusButton responseId={id} currentStatus={status} status="published" onUpdate={updateResponse} />
                <ResponseStatusButton responseId={id} currentStatus={status} status="archived" onUpdate={updateResponse} />
            </>
        )


    }
}

export default ResponseListItemActionContainer;
