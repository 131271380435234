import React, {Component} from "react";

import {Menu} from "semantic-ui-react";
import {Link, Router} from "@reach/router";
import _ from "lodash/string";

import ReviewsTab from "./ReviewsTab";

class ReviewsPage extends Component {

    state = { reviews : true };

    isActive = (name, isCurrent) => {
        if (this.state[name] !== isCurrent) {
            this.setState({ [name] : isCurrent })
        }
    };

    render() {
        const { reviews } = this.state;
        return (
        <div>
                <Menu pointing secondary style={{margin: 0}}>
                    <Link getProps={({isCurrent, location}) => this.isActive('reviews', isCurrent || _.endsWith(location.pathname, 'reviews/new'))} to="./" >
                        <Menu.Item icon="user" name='Review Requests' active={reviews} />
                    </Link>

                </Menu>

                <Router>
                    <ReviewsTab path="/*"/>
                </Router>
            </div>
        );
    }

}


export default ReviewsPage;
