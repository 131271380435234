import * as React from 'react';
import Logo from './Logo';

import {Button, Checkbox, Form, Grid, Message, Segment} from 'semantic-ui-react'

import {SignIn as AmplifySignIn} from 'aws-amplify-react';


class SignIn extends AmplifySignIn {

    showComponent(theme) {
        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Logo size='large'/>
                    <br/>
                    <Form size='large' onSubmit={this.signIn}>
                        <Segment stacked>
                            <Form.Input key='email' name='email' fluid icon='user' iconPosition='left' placeholder='E-mail address' onChange={this.handleInputChange} />
                            <Form.Input key='password' name='password' fluid icon='lock' iconPosition='left' placeholder='Password' type='password' onChange={this.handleInputChange} />

                            <Grid columns={2}>
                                <Grid.Column textAlign='left'>
                                    <Checkbox label='Keep me signed in' />
                                </Grid.Column>
                                <Grid.Column textAlign='right'>
                                    <a href='#forgot-password'>Forgot password?</a>
                                </Grid.Column>
                            </Grid>
                            <br/>
                            <Button type='submit' color='purple' fluid size='large'>Sign In</Button>
                        </Segment>
                        <Message>Don't have an account? <a href='https://reviewsloop.com'>Contact Us</a></Message>
                    </Form>
                    <br/>
                    <p><a href="https://toptalentcom.com/">© TOPTALENT COMMUNICATIONS</a></p>
                </Grid.Column>
            </Grid>
        )
    }
}


export default SignIn;
