import React, {Component} from "react";
import EmailEditor from "../components/EmailEditor";
import {Button, Dimmer, Loader, Segment, Step} from "semantic-ui-react";
import ContactsListSegment from "./ContactsListSegment";
import ReviewPreview from "./ReviewPreview";
import * as mutations from '../graphql/mutations'
import {API, graphqlOperation, Auth} from "aws-amplify";
import {Location, navigate} from "@reach/router";
import * as queries from "../graphql/queries";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import { toast } from 'react-semantic-toasts';
import _ from 'lodash';


class ReviewForm extends Component {

    constructor(props) {
        super(props);

        const { review, location } = props;

        let active = 'Recipients';

        if (review && review.message) {
           active = 'Preview'
        } else if (review && review.contacts && review.contacts.length !== 0 ) {
            active = 'Compose'
        }

        this.state = {
            active,
            recipients:  [],
            contacts: [],
            groups: [],
            groupContacts: [],
            currentGroup: {},
            subject: 'Thank you for applying',
            message: '<p>Thank you for choosing us. We would love to know your experience of our services.</p><p>Click one of the stars below to leave a review...</p>',
            loading: false,
        }
    }

    componentDidMount(): void {
        const { location } = this.props;
        if (location && location.state && location.state.contacts) {
            this.addRecipients(location.state.contacts)
        }
        if (location && location.state && location.state.groups) {
            this.addGroups(location.state.groups)
        }
        if (location && location.state && location.state.groupContacts) {
            this.addGroupContacts(location.state.groupContacts)
        }
    }

    getSenderEmail = async (user) => {
        const { data : { listSESs } } = await API.graphql(graphqlOperation(queries.listSeSs, {userId:  user.id }));
        if (listSESs && listSESs.items.length > 0) {
            const ses = listSESs.items[0];
            if (ses.status === 'Success') {
                return `${user.account.name} Experience <${ses.email}>`;
            }
        }
        return `${user.account.name} Experience <no-reply@reviewsloop.com>`;
    };

    handleSend = async (baseUrl) => {

        this.setState({loading: true});
        const { message, recipients, subject} = this.state;

        const { data : { getUser : user } } = await API.graphql(graphqlOperation(queries.getUser, {id: (await Auth.currentAuthenticatedUser()).username}));

        const input = {
            title: subject,
            message: message,
            status: 'draft',
            reviewUserId: user.id
        };
        try {
            const { data: { createReview } }= await API.graphql(graphqlOperation(mutations.createReview, {input}));
            for(let i = 0; i < recipients.length; i++) {
                await API.graphql(graphqlOperation(
                    mutations.createReviewContact,
                    {
                        input : {
                            reviewContactContactId: recipients[i].id,
                            reviewContactReviewId: createReview.id
                        }
                    })
                );
            }

            const { data: { getReview }} = await API.graphql(graphqlOperation(queries.getReview, { id: createReview.id }));

            const contacts = getReview.contacts.items.map((reviewContact) => {
                const { id: reviewContactId, contact: {firstName, lastName, email }} = reviewContact;
                return {id: reviewContactId, firstName, lastName, email}
            });

            const senderEmail = await this.getSenderEmail(user);

            const res = await API.graphql(graphqlOperation(mutations.sendReview,
                {
                    input: {
                        from: senderEmail,
                        contacts: contacts,
                        subject: getReview.title,
                        body: getReview.message,
                        baseUrl
                    }
                }
            ));
            console.log(res);
            this.setState({loading: false});
            navigate('..');
            toast({
                type: 'success',
                icon: 'envelope',
                title: 'Success',
                description: `Review Request has been sent`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        } catch (e) {
            console.error(e);
            toast({
                type: 'error',
                icon: 'envelope',
                title: 'Error',
                description: `Could not send review request. Please try again.`,
                animation: 'bounce',
                time: 5000,
                size: 'mini'
            });
        }
    };

    addRecipients = (contactIdList) => {
        const recipients = [...new Set(contactIdList)];
        this.setState({
            recipients
        });
    };

    addContacts = (contactIdList) => {
        const { groups, groupContacts } = this.state;
        const contacts = _.unionBy(contactIdList, 'id');
        let recipients = this.mergeContactAndGroups(contacts, groups, groupContacts);
        this.setState({
            recipients,
            contacts
        });
    };

    addGroups = (groupList) => {
        const { contacts, groupContacts } = this.state;
        const groups = _.uniqBy(groupList, 'id');
        const mergedGroupContacts =  _.unionBy(groupContacts, groupList.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted), 'id');
        let recipients = this.mergeContactAndGroups(contacts, groups, groupContacts);
        this.setState( {
            recipients,
            groups,
            groupContacts: mergedGroupContacts
        });
    };

    addGroupContacts = (groupContactsList) => {
        const { contacts, groups, groupContacts, currentGroup } = this.state;
        const currentGroupContacts = _.remove(groupContacts, (o) => o.group.id === currentGroup.id);
        const groupContactsUpdated = _.unionBy(groupContacts, groupContactsList, 'id');

        let recipients = this.mergeContactAndGroups(contacts, groups, groupContactsUpdated);
        this.setState( {
            recipients,
            groupContacts: groupContactsUpdated,
        });
    };

    setCurrentGroup = (group) => {
        this.setState({currentGroup: group})
    };

    mergeContactAndGroups(contacts, groups, groupContacts) {
        let recipients = contacts;
        for (let group of groups) {
            recipients = _.unionBy(recipients, group.contacts.items.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted).map(groupContact => groupContact.contact), 'id')
        }
        for (let groupContact of groupContacts) {
            recipients = _.unionBy(recipients, groupContacts.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted).map(groupContact => groupContact.contact), 'id')
        }
        return recipients;
    }

    updateMessage = (message) => {
        this.setState({
            message
        });
    };

    handleClick = (e, { title }) => this.setState({ active: title });

    canEdit = () => {
        const { review } = this.props;
        return !review || (review && (review.status !== 'sent' || review.status !== 'expired'));
    };

    disableRecipients = () => {
        return !this.canEdit();
    };

    disableCompose = () => {
        const { recipients } = this.state;
        return !this.canEdit() || recipients.length === 0
    };

    disablePreview = () =>  {
        const { message } = this.state;
        return this.disableCompose() || message === '';
    };

    render() {

        const { active, recipients, message,subject, loading, contacts, groups, groupContacts } = this.state;

        let showNext, nextTitle, nextDisabled, showPrev, prevTitle, prevDisabled, showSend;
        switch(active) {
            case 'Recipients': {
                showNext = true;
                nextTitle = 'Compose';
                nextDisabled = this.disableCompose();
                showPrev = false;
                prevTitle = '';
                prevDisabled = true;
                break;
            }
            case 'Compose': {
                showNext = true;
                nextTitle = 'Preview';
                nextDisabled = this.disablePreview();
                showPrev = true;
                prevTitle = 'Recipients';
                prevDisabled = false;
                break;
            }
            case 'Preview': {
                showNext = false;
                nextTitle = '';
                nextDisabled = true;
                showPrev = true;
                prevTitle = 'Compose';
                prevDisabled = false;
                showSend = true;
                break;
            }
            default: break;
        }

        return (
            <div>
                <Dimmer active={loading} page><Loader /></Dimmer>
                <Step.Group widths={3} style={{margin: 0}}>
                    <Step
                        active={active === 'Recipients'}
                        icon='group'
                        link
                        onClick={this.handleClick}
                        title='Recipients'
                        description='Choose the recipients from your contacts'
                        disabled={this.disableRecipients()}
                    />

                    <Step
                        active={active === 'Compose'}
                        icon='write'
                        link
                        onClick={this.handleClick}
                        title='Compose'
                        description='Write a custom message to your recipients'
                        disabled={this.disableCompose()}
                    />

                    <Step
                        active={active === 'Preview'}
                        icon='eye'
                        link
                        onClick={this.handleClick}
                        title='Preview'
                        disabled={this.disablePreview()}
                    />
                </Step.Group>

                <Segment basic clearing style={{padding: 0}}>
                {
                    showNext &&
                    <Button floated='right' animated disabled={nextDisabled} onClick={(e) => this.handleClick(e, {title: nextTitle})}>
                        <Button.Content visible>Next</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow right' />
                        </Button.Content>
                    </Button>
                }
                {
                    showPrev &&
                    <Button floated='left' animated disabled={prevDisabled} onClick={(e) => this.handleClick(e, {title: prevTitle})}>
                        <Button.Content visible>Back</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow left' />
                        </Button.Content>
                    </Button>
                }
                {
                    showSend &&
                    <Location>
                        {({location}) => {
                            return (
                                <Button color='purple' floated='right' animated onClick={() => this.handleSend(location.origin)}>
                                    <Button.Content visible>Send</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='send' />
                                    </Button.Content>
                                </Button>
                            )
                        }}
                    </Location>
                }
                </Segment>

                <Segment >
                    {
                        active === 'Recipients' && <ContactsListSegment recipients={recipients} contacts={contacts} onRecipientsAdded={this.addContacts} groups={groups} onGroupsAdded={this.addGroups} groupContacts={groupContacts} onGroupContactsAdded={this.addGroupContacts} onGroupSelect={this.setCurrentGroup} onNext={(e) => this.handleClick(e, 'Compose')} nextDisabled={this.disableCompose()}/>
                    }
                    {
                        active === 'Compose' && <EmailEditor body={message} subject={subject} onChange={this.updateMessage} onSubjectChange={(subject) => this.setState({subject})} title={'Recipients'} onNext={(e) => this.handleClick(e, 'Preview')} />
                    }
                    {
                        active === 'Preview' && <ReviewPreview recipients={recipients} body={message} subject={subject} />


                    }

                </Segment>

            </div>
        );
    }

}


export default ReviewForm;
