import styled from 'styled-components';

import {Button} from 'semantic-ui-react';

const ReviewLoopActionButton = styled(Button)`
    background-color: white !important;
    padding: 1 !important;
    box-shadow: 'none !important';
    
    :hover {
        background-color: #f0f0f0 !important;
        box-shadow: 0px 2px 3px 0px #dedede !important
    }
    
    :active {
        background-color: lightgrey !important;
    }
`;

export default ReviewLoopActionButton;
