import React, {Component} from "react";

import {Menu} from "semantic-ui-react";

import {Link, Router} from "@reach/router";
import ResponseListWithData from "./ResponseListWithData";
import ResponseDetail from "./ResponseDetail";

class ResponsesPage extends Component {

    state = { activeItem: 'home', new: true, archived: false, published: false, all: false };

    isActive = ({isCurrent}) => isCurrent ? {className: "active item"} : null;

    isPartiallyActive = ({isPartiallyCurrent}) => isPartiallyCurrent ? {className: "active item"} : null;

    render() {
        return (
            <div>
                <Menu pointing secondary style={{margin: 0}}>
                    <Menu.Item as={Link} getProps={this.isActive} to="./" icon="inbox" name='New' />
                    <Menu.Item as={Link} getProps={this.isPartiallyActive} to="archived" icon="archive" name='Archived' active={this.state.archived} />
                    <Menu.Item as={Link} getProps={this.isPartiallyActive} to="published" icon="world" name='Published' active={this.state.published} />
                    <Menu.Item as={Link} to="all" getProps={this.isPartiallyActive} icon="star" name='All' active={this.state.all} />

                    <Menu.Menu position='right'>
                        <Menu.Item>
                            {/*<Input*/}
                            {/*    transparent*/}
                            {/*    icon={{ name: 'search', link: true }}*/}
                            {/*    placeholder='Search...'*/}
                            {/*/>*/}
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

                <Router>
                    <ResponseDetail path="/view/:responseId"/>
                    <ResponseListWithData path="/:tab" />
                    <ResponseListWithData path="*/:tab"/>
                </Router>
            </div>
        );

    }

}


export default ResponsesPage;
