import React from "react";
import { Tab} from "semantic-ui-react";
import Users from "./Users";
import Accounts from "./Accounts";


function Admin(props) {
    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={[
                {
                    menuItem: { key: 'accounts', icon: 'building', content: 'Accounts' },
                    render: () => <Tab.Pane attached={false}><Accounts /></Tab.Pane>,
                },
                {
                    menuItem: { key: 'users', icon: 'user', content: 'Users'},
                    render: () => <Tab.Pane attached={false}><Users /></Tab.Pane>,
                },

            ]}
        />
    )
}

export default Admin;
