import React, {Component} from 'react';
import _ from 'lodash'
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";
import {Dropdown,  Input, Loader} from "semantic-ui-react";
import {Link} from "@reach/router";
import ReviewLoopActionButton from "./ReviewLoopActionButton";


class GroupListSegment extends Component {

    state = {
        items: [],
        searchItems: [],
        errors: [],
        loading: true,
        loaded: false,
        name: '',
        isMatch: false
    };

    componentDidMount = async () => {
        this.fetchItems();
    };

    fetchItems = async () => {
        try {
            const { data : { listGroups } } = await API.graphql(graphqlOperation(queries.listGroups, {limit: 1000}));
            const items = listGroups ? listGroups.items.map(item => ({key: item.id, value: item.name, text: item.name})) : [];
            this.setState({ items, searchItems: items, loading: false, loaded: true });
        } catch (e) {
            this.setState({ errors: [e], loading: false, loaded: true });
        }
    };

    handleSearchClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleSearch = (e, {value}) => {
        e.stopPropagation();
        e.preventDefault();
        const re = new RegExp(_.escapeRegExp(value));
        const filtered = this.state.items.filter((opt) => re.test(opt.text));

        this.setState({searchItems: filtered, name: value, isMatch: filtered.indexOf(value) >= 0});
    };


    render() {
        const { searchItems, loading, name, isMatch} = this.state;
        const { items: contacts, selected, onClick, action } = this.props;

        if (loading) {
            return (<Loader active={loading} />)
        }

        return (
            <Dropdown
                icon={null}
                trigger={<ReviewLoopActionButton icon={action.icon} circular
                />}
                >
                <Dropdown.Menu>
                    <Dropdown.Header icon='group' content='Groups' />
                    <Input icon='search' iconPosition='left' className='search' value={name} onClick={this.handleSearchClick} onChange={this.handleSearch}/>
                    <Dropdown.Menu scrolling>
                        {
                            (name !== '' && !isMatch) && <Dropdown.Item text={(<b>"{name}" (create new)</b>)} value={name} onClick={() => onClick(contacts, selected, {name})}/>
                        }
                        {searchItems.map((option) => (
                            <Dropdown.Item key={option.key} {...option} onClick={() => onClick(contacts, selected, {id: option.key, name: option.text})}/>
                        ))}
                        <Dropdown.Divider />
                        {
                            name === '' && (
                                <>
                                    <Dropdown.Item as={Link} to='groups/add' state={{ contacts: selected }} text='Create Group' />
                                </>
                            )
                        }
                        <Dropdown.Item as={Link} to='groups' text='Manage Groups' />
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Dropdown>
        )
    }



}

export default GroupListSegment;
