import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {Button, Header, Icon} from "semantic-ui-react";
import Papa from "papaparse";
import _ from "lodash/string";
import fileDownload from "js-file-download";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const FileDragAndDrop = (props) => {

    const handleFileSelect = (acceptedFiles) => {
        const { onFileProcessed } = props;

        if (!acceptedFiles || acceptedFiles.length !== 1) {
            return;
        }

        Papa.parse(acceptedFiles[0], {
            header: true,
            transformHeader: (value) => _.lowerFirst(_.camelCase(value)),
            skipEmptyLines: true,
            complete: (results, file) => {
                const { data, errors } = results;
                if (errors && errors.length > 0) {
                    console.log("Error parsing the file: " + errors);
                    for(let e of errors) {
                        console.error(e);
                    }
                    return;
                }

                onFileProcessed(file, data)
            },
            error: (error) => {
                console.log("Error parsing the file: " + error);
            }
        });
    };

    const handleDownloadTemplateFile = () => {
        const fields = ["First Name", "Last Name", "Email", "Phone"];
        const data = [["Bob", "Dylan", "bob.dylan@deleteme.com", "222-333-4444"]];
        const csvString = Papa.unparse({fields, data}, {quotes: true});
        fileDownload(csvString, 'reviewsloop_contacts_template.csv');
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({onDrop: handleFileSelect , accept: '.csv', multiple: false});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <>
            <span onClick={handleDownloadTemplateFile} style={{float: 'right',color: '#4183C4', textDecoration: 'none', backgroundColor: 'transparent', cursor: "pointer"}}><Icon name='download' color='grey'/><b>Download Sample Template</b></span>
            <br/>
            <br/>
            <div className="container">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <Header icon>
                        <Icon name='file outline' />
                        Drag 'n' drop
                    </Header>
                    <Button color='purple' >Select File</Button>
                </div>
            </div>
        </>
    );
};

export default FileDragAndDrop
