import React, {Component} from 'react';
import _ from 'lodash';
import {Menu, Modal} from "semantic-ui-react";

import ContactsList from "./ContactsList";
import GroupContactsList from "./GroupContactsList";
import ContactsGroupList from "./ContactsGroupList";

class ContactsListSegment extends Component {

    state = { activeItem: 'Contacts', groupContactsModalOpen: false, group: {} };

    handleItemClick = (e, { name }) => this.setState({ activeItem: name });

    handleGroupContactsModalOpen = (group) => {
        this.setState({groupContactsModalOpen: true, group});
        if (this.props.onGroupSelect) {
            this.props.onGroupSelect(group);
        }
    };

    handleGroupContactsModalClose = () => {
        this.setState({groupContactsModalOpen: false, group: {}})
        if (this.props.onGroupSelect) {
            this.props.onGroupSelect(null);
        }
    };

    render() {
        const { activeItem, groupContactsModalOpen, group } = this.state;
        const { recipients, contacts, onRecipientsAdded, onGroupsAdded, onGroupContactsAdded, groups, groupContacts } = this.props;
        return (
            <div>
                <Menu pointing secondary style={{margin: 0}}>
                    <Menu.Item icon="user" name='Contacts' active={activeItem === 'Contacts'} onClick={this.handleItemClick}/>
                    <Menu.Item icon="group" name='Groups' active={activeItem === 'Groups'} onClick={this.handleItemClick}/>
                    <Menu.Menu position='right'>
                        <Menu.Item>

                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                {
                    activeItem === 'Contacts' && <ContactsList listActions={false} selected={recipients} onItemSelectionChange={onRecipientsAdded}/>
                }
                {
                    activeItem === 'Groups' &&
                        <>
                            <ContactsGroupList
                                listActions={false}
                                selected={groups}
                                onItemSelectionChange={onGroupsAdded}
                                onClick={this.handleGroupContactsModalOpen}
                                indeterminate={
                                    ({item}) => {
                                        const itemGroupContacts = item.contacts.items.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted)
                                        const groupGroupContacts = _.filter(groupContacts, (o) => o.group.id === item.id)
                                        for (const gc of itemGroupContacts) {
                                            if (_.findIndex(groupGroupContacts, (o) => o.id === gc.id) >= 0) {
                                                return true
                                            }
                                        }
                                        return false;
                                    }
                                }
                            />
                            { groupContactsModalOpen &&
                                <Modal onClose={this.handleGroupContactsModalClose} open={groupContactsModalOpen}>
                                    <Modal.Header>Group Contacts</Modal.Header>
                                    <Modal.Content>
                                        <GroupContactsList
                                            listActions={false}
                                            itemActions={false}
                                            loaded={true}
                                            loading={false}
                                            selected={_.filter(groupContacts, ['group.id', group.id])}
                                            items={group.contacts ? group.contacts.items.filter(groupContact => !groupContact.deleted && groupContact.contact && !groupContact.contact.deleted) : []}
                                            refresh={() => {}}
                                            onItemSelectionChange={onGroupContactsAdded} />
                                    </Modal.Content>
                                </Modal>
                            }
                        </>
                }
            </div>
        );

    }

}

export default ContactsListSegment
