import * as React from 'react';
import {Menu} from 'semantic-ui-react'
import ReviewLoopMenuButton from "../ReviewLoopMenuButton";

export default class SideMenu extends React.Component {
    render() {
        return (
            <div >
                <Menu icon vertical secondary borderless>
                    <ReviewLoopMenuButton icon='star' to='reviews' tooltip='Reviews' color='purple' />
                    <ReviewLoopMenuButton icon='address book' to='contacts' tooltip='Contacts' color='blue' />
                    <ReviewLoopMenuButton icon='mail' to='requests' tooltip='Requests' color='red' />
                </Menu>
            </div>
        )
    }
}
